import React from 'react';
import ErrorBoundary from 'modules/partial/containers/ErrorBoundary';
import { ModalMarker } from 'modules/common/components/Modal';
import { EndActionMarker } from 'modules/payment/components/EndAction';
import Stage from 'modules/common/components/Stage';
import ToastMarker from './toast/index';
import Public from './Public';

function App() {
  return (
    <ErrorBoundary>
      <Stage />
      <ToastMarker />
      <ModalMarker />
      <Public />
      <EndActionMarker />
    </ErrorBoundary>
  );
}

export default App;
