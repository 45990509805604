import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import PhoneInput from 'react-phone-input-2';

function FormPhoneNumber({
  label,
  name,
  onChange,
  value,
  icon,
  componentProps,
  ...props
}) {
  const [country] = React.useState('ph');
  const handleChange = (newValue) => {
    onChange((state) => ({
      ...state,
      [name]: isEmpty(newValue) ? '' : `${newValue}`,
    }));
  };
  const otherProps = React.useMemo(
    () => ({
      // pattern: '[0-9]*',
      onKeyPress: (e) => {
        const charCode = typeof e.which === 'undefined' ? e.keyCode : e.which;
        const charStr = String.fromCharCode(charCode);
        if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
      },
    }),
    []
  );
  return (
    <>
      <label className="text-xs text-gray-600 font-thin flex items-center">
        <span>{label}</span>
      </label>
      <div className="form-group-wrapper">
        {icon && (
          <span className="form-group-prepend p-0">
            <img src={icon} alt="Name" className="img-cc" />
          </span>
        )}
        <input
          className="absolute opacity-0 pointer-events-none form-control"
          onChange={() => {}}
          value={value}
          onFocus={() => {
            const el = document.getElementById('mobile-number-input');
            if (el) el.focus();
          }}
          {...props}
        />
        <PhoneInput
          country={country}
          onChange={handleChange}
          placeholder=""
          value={value}
          masks={{
            ph: '...-...-....',
          }}
          priority={{ ph: 0 }}
          enableSearch
          disableDropdown
          countryCodeEditable
          specialLabel=""
          containerClass="w-full"
          searchPlaceholder="Search..."
          inputProps={{
            ...otherProps,
            ...props,
            id: 'mobile-number-input',
          }}
          {...componentProps}
        />
      </div>
    </>
  );
}

FormPhoneNumber.defaultProps = {
  label: '',
  componentProps: {},
};

FormPhoneNumber.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  componentProps: PropTypes.instanceOf(Object),
  icon: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.bool,
    PropTypes.string,
  ]),
};

export default React.memo(FormPhoneNumber);
