import React from 'react';
// import PropTypes from 'prop-types';
import qs from 'query-string';

export const PDF_URLS = {
  smart:
    'https://drive.google.com/file/d/1aPnjXSOWsoWgO1mps0gBa4EoAXww0cn6/preview#toolbar=0&navpanes=0&scrollbar=0',
  pldt:
    'https://drive.google.com/file/d/1VD62NvQzPlQyk2CgFZ2aSDOpfNUKLe2p/preview#toolbar=0&navpanes=0&scrollbar=0',
  csb:
    'https://drive.google.com/file/d/15ratXCtJaBQcWSqvz7q-zM1Vhg4wu6oR/preview#toolbar=0&navpanes=0&scrollbar=0',
};

function PdfDetails() {
  const [pdf, setPdf] = React.useState(null);
  const [zoom, setZoom] = React.useState(false);
  const params = qs.parse(window.location.search);
  React.useEffect(() => {
    if (params.pdf) {
      const url = PDF_URLS[params.pdf];
      if (url) setPdf(url);
    }
  }, [params]);
  const handleClick = () => {
    setZoom(true);
  };
  const handleClose = () => {
    setZoom(false);
  };
  if (!pdf) return null;
  return (
    <div className="pdf-details">
      <div className="pointer-events-none h-40 overflow-hidden">
        {/* <embed
          className="w-full"
          src={pdf}
          type="application/pdf"
        /> */}
        <iframe
          width="100%"
          height="250px"
          src={`${pdf}?scroll=0#toolbar=0`}
          type="application/pdf"
          style={{ height: 10000 }}
          frameBorder="0"
          scrolling="no"
          seamless="seamless"
          title={params.url}
        />
      </div>
      <div className="border-b py-2 mb-4">
        <button
          className="btn py-1 outline-primary rounded w-full"
          type="button"
          onClick={handleClick}
        >
          <div className="pointer-events-none">
            <span role="img" aria-label="zoom">
              &#x1F50D;
            </span>
            <span className="ml-2">View SOA</span>
          </div>
        </button>
      </div>
      {zoom && (
        <div className="lightbox">
          <div className="lightbox-close">
            <button type="button" onClick={handleClose}>
              <span>&times;</span>
            </button>
          </div>
          <div className="iframe-wrapper">
            <iframe
              frameBorder="0"
              src={`${pdf}#toolbar=0`}
              title="preview"
              height="100%"
            />
          </div>
        </div>
      )}
    </div>
  );
}

PdfDetails.propTypes = {};

export default PdfDetails;
