import React from 'react';
// import PropTypes from 'prop-types';
import { useApiGet } from 'react-reqq';
import { useParams } from 'react-router-dom';
import TransactionError from '../components/TransactionError';
import TransactionDetails from '../components/TransactionDetails';
import PaymentMethodContainer from '../components/PaymentMethodContainer';
// import IFrameAuth from '../components/IFrameAuth';
import * as actions from '../actions';
import * as c from '../constants';

function PaymentContainer() {
  const { refno } = useParams();
  const data = useApiGet(c.INQUIRE, {});
  React.useEffect(() => {
    actions.inquire(refno);
  }, [refno]); // eslint-disable-line
  return (
    <div className="container mx-auto max-w-700 receipt-mask">
      <div className="bg-white shadow-lg flex flex-wrap divide-x overflow-hidden relative pb-5">
        <div className="pb-0 pt-3 md:p-3 w-full sm:w-2/5 mb-0 md:mb-3">
          <div className="text-center pb-3 hidden sm:block">
            <div className="text-red-600 font-bold text-sm leading-snug">
              Your Transaction
            </div>
            <div className="text-xs text-gray-800">
              Please verify all information below.
            </div>
          </div>
          {data.error ? (
            <TransactionError data={data} />
          ) : (
            <TransactionDetails data={data} />
          )}
        </div>
        <div className="p-3 w-full sm:w-3/5">
          <div className="text-center">
            <div className="text-red-600 font-bold text-sm leading-snug">
              Payment Method
            </div>
            <div className="text-xs text-gray-800">
              All transactions are secured and encrypted.
            </div>
            <PaymentMethodContainer />
          </div>
        </div>
      </div>
      {/* <IFrameAuth /> */}
    </div>
  );
}

PaymentContainer.propTypes = {};

export default PaymentContainer;
