import React from 'react';
// import PropTypes from 'prop-types';

function Footer() {
  return (
    <div className="text-center mt-4 text-2xs mb-3">
      <div className="font-light text-gray-600">Powered by MultiSys</div>
      <div className="font-light text-gray-600">
        © 2021 All rights reserved.
      </div>
    </div>
  );
}

Footer.propTypes = {};

export default Footer;
