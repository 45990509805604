import React from 'react';

function Stage() {
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setShow(sessionStorage.getItem('hide_env_warning') !== '1');
    }, 250);
  }, []);
  const toggle = (e) => {
    e.preventDefault();
    setShow(!show);
    sessionStorage.setItem('hide_env_warning', show ? '1' : '0');
  };
  if (process.env.REACT_APP_IS_PRODUCTION === '1') return null;
  return (
    <>
      <div
        className={`fixed top-0 left-0 w-full bg-red-500 pointer-events-none font-bold text-center z-50 transform transition duration-500 ease-out overflow-hidden ${
          show ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        <span className="text-white">You are on test environment. </span>
        <a
          className="text-white underline pointer-events-auto hover:text-gray-200"
          href="https://safify.com"
          onClick={toggle}
        >
          Got it!
        </a>
      </div>
      <div
        className={`fixed top-0 left-0 px-1 pointer-events-none font-bold z-50 transform transition duration-500 ease-out overflow-hidden ${
          !show ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        <a
          className="pointer-events-auto text-red-500"
          href="/"
          onClick={toggle}
        >
          <svg
            className="w-6 h-6 mt-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </a>
      </div>
    </>
  );
}

Stage.propTypes = {};

export default Stage;
