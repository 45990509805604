import React from 'react';
import PropTypes from 'prop-types';
import { useApiGet } from 'react-reqq';
import { get, isEmpty } from 'lodash';
import GenericView, { ViewItem } from 'modules/common/components/GenericView';
import { parseNumber, formatNumber } from 'modules/common/helper';
import PdfDetails from '../components/PdfDetails';
import biller_thumb from 'assets/img/logo-biller.jpg';
import * as c from '../constants';

const STATUS_MAPPER = {
  I: 'Initial',
  P: 'Pending',
  S: 'Paid',
  F: 'Failed',
};

const STATUS_CLASS_MAPPER = {
  I: 'bg-gray-300 text-gray-900',
  P: 'bg-orange-400 text-white',
  S: 'bg-green-500 text-white',
  F: 'bg-red-600 text-white',
};

const renderStatus = (row) => {
  if (row.status === 'I' && row.is_link_expired)
    return (
      <span className="inline-block bg-gray-600 text-white rounded px-2 py-1 uppercase text-xs">
        Expired
      </span>
    );
  if (row.status === 'I' && row.is_transaction_expired)
    return (
      <span className="inline-block bg-gray-600 text-white rounded px-2 py-1 uppercase text-xs">
        Expired
      </span>
    );
  return (
    <span
      className={`inline-block ${
        STATUS_CLASS_MAPPER[row.status]
      } rounded px-2 py-1 uppercase text-xs`}
    >
      {STATUS_MAPPER[row.status] || 'n/a'}
    </span>
  );
};

function TransactionDetails({ data }) {
  const isLoading = isEmpty(data);
  const paymentMethod = useApiGet(c.PAYMENT_METHOD, {});
  const paymentRates = useApiGet(`${c.PAYMENT_OPTIONS}/rates`, {});

  const { amount, fee, method_fee, total_amount } = React.useMemo(() => {
    const code = get(data, 'payment_channel.code') || paymentMethod.code;

    const amount = parseNumber(data.amount, 0);
    const fee = parseNumber(data.fee, 0);
    const rate = parseNumber(get(paymentRates, `${code}.rate`), 0);
    const rate_type = get(paymentRates, `${code}.type`);
    const method_fee =
      rate_type === 'PERCENTAGE' ? (amount + fee) * rate : rate;
    const total_amount = amount + fee + method_fee;

    return {
      amount,
      fee,
      method_fee,
      total_amount,
    };
  }, [data, paymentMethod, paymentRates]);

  const withExternal =
    Object.keys(data?.external_payment_instructions || {}).length > 0;

  return (
    <>
      <div className="px-3 md:px-0 mt-10 sm:mt-0">
        <PdfDetails />
        <div className="flex">
          <div
            className={`h-16 mb-1 md:mb-3 w-2/3 inline-block flex content-center justify-center mx-auto${
              isLoading ? ' animate-pulse' : ''
            }`}
          >
            {!isEmpty(data) && (
              <img
                className="object-contain object-center"
                src={data.logo || biller_thumb}
                alt={data.institution}
              />
            )}
          </div>
        </div>
        <GenericView isLoading={isLoading} data={data}>
          {withExternal ? (
            <div>
              <ViewItem label="Transaction No." value="refno" />
            </div>
          ) : null}
          <div>
            <ViewItem label="Biller" value="institution" />
          </div>
          {!withExternal ? (
            <div>
              <div className="w-full">
                <ViewItem
                  label="Amount"
                  value={() => (
                    <>
                      <span>₱</span>
                      <span>{formatNumber(amount)}</span>
                    </>
                  )}
                />
              </div>
              <div className="w-full text-right">
                <ViewItem
                  label="Convenience Fee"
                  value={() => (
                    <>
                      <span>₱</span>
                      <span>{formatNumber(fee)}</span>
                    </>
                  )}
                  loadingClassName="bg-gray-200 w-full rounded animate-pulse my-auto"
                />
              </div>
            </div>
          ) : null}
          {!withExternal ? (
            <div className="flex">
              <div className="w-full">
                <ViewItem
                  label="Total Amount"
                  value={() => (
                    <div className={method_fee <= 0 ? 'text-red-600' : ''}>
                      <span>₱</span>
                      <span>{formatNumber(amount + fee)}</span>
                    </div>
                  )}
                />
              </div>
            </div>
          ) : null}
          {method_fee > 0 && (
            <div className="flex">
              <div className="w-full">
                <ViewItem
                  label="Total w/ Service Fee"
                  value={() => (
                    <div className="text-red-600 text-right">
                      <span>₱</span>
                      <span>{formatNumber(total_amount)} </span>(<span>₱</span>
                      <span>{formatNumber(method_fee)}</span>)
                    </div>
                  )}
                />
              </div>
            </div>
          )}
          <div>
            <ViewItem label="Status" value={renderStatus} />
          </div>
        </GenericView>
      </div>
    </>
  );
}

TransactionDetails.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default React.memo(TransactionDetails);
