import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { get } from 'lodash';
import DetailsContainer from 'modules/payment/containers/DetailsContainer';
import CheckoutContainer from 'modules/payment/containers/CheckoutContainer';
import CheckoutBpiContainer from 'modules/payment/containers/CheckoutBpiContainer';
import PaymentContainer from 'modules/payment/containers/PaymentContainer';
import Page404 from 'modules/partial/containers/Page404';
import Footer from 'modules/partial/components/Footer';
import MaintenancePage from 'modules/partial/containers/MaintenancePage';
import Version from 'modules/Version';
import CloseWindow from 'modules/partial/containers/CloseWindow';
import BackAction from 'modules/common/components/BackAction';

const MAINTENANCE_MODE = !(
  window.location.origin.indexOf('.cloudfront.net') < 0
);

const LegacyRoute = () => {
  const match = useRouteMatch('/pgi/:refno');
  const refno = match?.params?.refno;
  return <Redirect to={`/${refno}`} />;
};

function Public() {
  if (MAINTENANCE_MODE) return <MaintenancePage />;
  return (
    <>
      <BackAction />
      <Switch>
        <LegacyRoute path="/pgi/:refno" />
        <Route
          path="/card/:refno"
          render={({ match }) => (
            <Redirect to={`/${get(match, 'params.refno')}`} />
          )}
        />
        <Route path="/payment-details/:refno">
          <DetailsContainer />
        </Route>
        <Route path="/bpi/:refno">
          <CheckoutBpiContainer />
        </Route>
        <Route path="/:refno/checkout">
          <CheckoutContainer />
        </Route>
        <Route path="/close-window">
          <CloseWindow />
        </Route>
        <Route path="/version">
          <Version />
        </Route>
        <Route path="/:refno">
          <PaymentContainer />
        </Route>
        <Route component={Page404} />
      </Switch>
      <Footer />
    </>
  );
}

Public.propTypes = {};

export default Public;
