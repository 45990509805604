import React from 'react';
import PropTypes from 'prop-types';
import not_found from 'assets/img/page-not-found.png';

function TransactionError({ data }) {
  return (
    <>
      <div className="flex py-5 absolute h-full w-full z-40 top-0 left-0 bg-white">
        <div className="m-auto w-full">
          <div>
            <img src={not_found} alt="not found" />
          </div>
          <div className=" text-red-400 text-center text-2xl mt-5 truncate px-5">
            {data.message || 'Transaction not found!'}
          </div>
        </div>
      </div>
      <div className="min-h-383" style={{ height: 383 }} />
    </>
  );
}

TransactionError.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default TransactionError;
