import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

function HelpInstructions({ data }) {
  if (!data?.instruction) return null;
  return (
    <div className="bg-gray-50 mt-2 text-gray-500 p-3 flex space-x-2 mb-2 rounded-md">
      <svg
        className="w-4 h-4 flex-shrink-0"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <div className="space-y-1">
        <div id="help-instructions" className="text-xs">
          {data?.instruction && (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data?.instruction || ''),
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

HelpInstructions.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default HelpInstructions;
