import React from 'react';
// import PropTypes from 'prop-types';
import { req, useApiGet } from 'react-reqq';
import * as actions from '../actions';
import * as c from '../constants';

const LoaderItem = () => (
  <div className="bg-gray-200 w-10 h-10 rounded animate-pulse mx-auto" />
);

function PaymentMethodWithSearch() {
  const data = useApiGet(c.INQUIRE, {});
  const category = useApiGet(c.PAYMENT_METHOD, null);
  const [list, setList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    actions.listCategoryChannel(data.refno, { data: category }, (newSub) => {
      setIsLoading(false);
      setList(newSub);
    });
  }, [data.refno, category]);
  const handleBack = () => {
    req.set(c.PAYMENT_METHOD, null);
  };
  return (
    <>
      <div className="font-semibold my-3 text-gray-700 text-xs truncate flex items-center">
        <button
          className="btn secondary rounded xs mr-3 inline-block"
          type="button"
          onClick={handleBack}
        >
          <div className="flex items-center">
            <svg
              className="w-5 h-5 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 16l-4-4m0 0l4-4m-4 4h18"
              />
            </svg>
            <span>Back</span>
          </div>
        </button>
        <span>{category?.name || 'Payments'}</span>
      </div>
      <div className="divider line one-line">
        You can pay cash to any of the following payment channels.
      </div>
      <div className={list.length > 12 ? 'ellipsis-trans-bottom' : ''}>
        <div className="h-170px overflow-auto px-4 pt-4">
          {/* <div className="overflow-auto px-4 pt-4"> */}
          <div className="grid grid-cols-5 gap-2 pointer-events-none relative">
            {list.length < 1 && isLoading ? (
              <>
                <LoaderItem />
                <LoaderItem />
                <LoaderItem />
                <LoaderItem />
                <LoaderItem />
                <LoaderItem />
                <LoaderItem />
                <LoaderItem />
                <LoaderItem />
                <LoaderItem />
                <LoaderItem />
                <LoaderItem />
              </>
            ) : (
              list.map((item) => (
                <div key={item?.data?.id} className="flex items-center">
                  <div className="relative w-10 h-10 rounded-lg overflow-hidden mx-auto">
                    <img
                      className="w-10 h-10 top-0 absolute object-contain"
                      src={item?.data?.logo}
                      alt=""
                    />
                  </div>
                  {/* <div className="text-xs text-gray-600">
                    {item?.data?.name}
                  </div> */}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <div className="divider line one-line">
        A reference number will be provided as your basis of payment.
      </div>
    </>
  );
}

PaymentMethodWithSearch.propTypes = {};

export default PaymentMethodWithSearch;
