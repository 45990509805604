import React from 'react';
// import PropTypes from 'prop-types';
import { useApiGet } from 'react-reqq';
import { useParams, Redirect } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import method_thumb from 'assets/img/logo-method.png';
import TransactionDetails from '../components/TransactionDetails';
import BpiCheckout from '../third-party/BpiCheckout';
import * as actions from '../actions';
import * as c from '../constants';

function CheckoutContainer() {
  const { refno } = useParams();
  const data = useApiGet(c.INQUIRE, {});

  React.useEffect(() => {
    actions.inquire(refno);
  }, [refno]); // eslint-disable-line

  // if (data.status === 'I') {
  //   return <Redirect to={`/payment-details/${refno}`} />;
  // }
  if (!isEmpty(data) && data.status !== 'P') {
    return <Redirect to={`/payment-details/${refno}`} />;
  }
  return (
    <div className="container mx-auto max-w-700 receipt-mask">
      <div className="bg-white shadow-lg flex flex-wrap divide-x overflow-hidden">
        <div className="p-2 sm:p-5 w-full sm:w-2/5">
          <div className="border-dashed border-b-2 pb-0 sm:pb-3 flex flex-col sm:flex-row items-center justify-center sm:justify-start">
            <div className="h-10 w-10 rounded-full overflow-hidden">
              <img
                className="object-fit"
                src={get(data, 'payment_channel.logo') || method_thumb}
                alt="Payment Method"
              />
            </div>
            <div className="ml-3 text-center sm:text-left">
              <div className="text-xs font-light text-gray-800 leading-none">
                Selected payment option:
              </div>
              <div className="text-red-600 font-bold text-lg">
                {get(data, 'payment_channel.name') || 'Loading...'}
              </div>
            </div>
          </div>
          {/* <div className="bg-gray-100 text-gray-800 font-thin p-4 mt-5">
            <div className="text-xs text-teal-500 font-semibold">
              Verify that the following information is correct.
            </div>
            <div className="text-xs text-gray-900">
              If you are satisfied with the payment information, click the Pay
              Now button.
            </div>
          </div> */}
          <div>
            <TransactionDetails data={data} />
          </div>
        </div>
        <div className="p-2 sm:p-5 w-full sm:w-3/5">
          <BpiCheckout refno={refno} />
        </div>
      </div>
    </div>
  );
}

CheckoutContainer.propTypes = {};

export default CheckoutContainer;
