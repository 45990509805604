import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import DOMPurify from 'dompurify';
import { req, useApiGet } from 'react-reqq';
import { toastError } from 'toast';

const isValidReturnUrl = (string) => {
  if (string === 'close-window') return true;
  try {
    new URL(string);
  } catch (_) {
    return false;
  }

  return true;
};

const getDefaultReturnUrl = () => {
  try {
    const url = DOMPurify.sanitize(sessionStorage.getItem('return_url'));
    return isValidReturnUrl(url) ? url : null;
  } catch (err) {
    return null;
  }
};

export const EndActionMarker = () => {
  const location = useLocation();
  React.useEffect(() => {
    const query = qs.parse(DOMPurify.sanitize(location.search));
    if (query.return_url && isValidReturnUrl(query.return_url)) {
      sessionStorage.setItem('return_url', query.return_url);
      req.set('RETURN_URL', query.return_url);
    }
  }, [location]);
  return null;
};

function EndAction({ refno }) {
  const returnUrl = useApiGet('RETURN_URL', getDefaultReturnUrl());
  const [isRedirecting, setIsRedirecting] = React.useState(false);
  const handleClose = () => {
    try {
      const message = {
        type: 'WINDOW_CLOSE',
      };
      window.parent.postMessage(JSON.stringify(message), '*');
    } catch (err) {
      // eslint-disable-next-line
      console.log('Unable to post message!', err);
    }

    if (returnUrl === 'REALTIME_REDIRECT') {
      setIsRedirecting(true);
      req.get({
        key: 'POST_REDIRECT',
        url: `/api/transactions/${refno}/redirect`,
        onSuccess: (res) => {
          const url = res?.response?.data?.url;
          if (!url) {
            setIsRedirecting(false);
            toastError(
              'Unable to get the return url. You may now close this window!'
            );
            return;
          }
          window.location.href = url;
        },
        onError: () => {
          setIsRedirecting(false);
          toastError(
            'Unable to get the return url. You may now close this window!'
          );
        },
      });
    }
    if (returnUrl === 'close-window' || !!window.opener) {
      try {
        window.parent.focus();
      } catch (err) {}
      window.close();
    }
    if (isValidReturnUrl(returnUrl)) window.location.href = returnUrl;
  };
  if (!returnUrl && window.opener === null) return null;
  return (
    <div>
      <div className="text-center text-xs mb-2">
        You may now close this window.
      </div>
      <div>
        <button
          className="btn primary w-full"
          type="button"
          onClick={handleClose}
          disabled={isRedirecting}
        >
          {isRedirecting ? 'Please wait...' : 'Close'}
        </button>
      </div>
    </div>
  );
}

EndAction.propTypes = {
  refno: PropTypes.string.isRequired,
};

export default EndAction;
