import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function ChevronLeftIcon(props) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      ></path>
    </svg>
  );
}

function isValidUrl(str) {
  // eslint-disable-next-line
  const urlPattern = /^(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9\-_\.]+(\.[a-zA-Z]{2,})?(:\d{1,5})?(\/\S*)?$/;
  return urlPattern.test(str);
}

function BackAction() {
  const location = useLocation();
  const originUrl = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const back = urlParams.get('back');
    if (back === 'true') return '#back';
    if (isValidUrl(back)) return back;
    return null;
  }, [location.search]);
  const handleBack = (e) => {
    e.preventDefault();
    if (isValidUrl(originUrl)) {
      window.location.replace(originUrl);
      return;
    }
    window.history.back();
  };
  if (!originUrl) return null;
  return (
    <div className="relative w-full bg-white border-b shadow-sm mb-0">
      <div className="container mx-auto h-12 flex items-center">
        <a className="flex items-center" href={originUrl} onClick={handleBack}>
          <ChevronLeftIcon className="h-6 w-6 mr-1" />
          <span className="text-sm font-semibold">Go Back</span>
        </a>
      </div>
    </div>
  );
}

export default BackAction;
