import React from 'react';
import PropTypes from 'prop-types';
import { req, useApiGet } from 'react-reqq';
import { get, isEmpty } from 'lodash';
import method_thumb from 'assets/img/logo-method.png';
import * as actions from '../actions';
import * as c from '../constants';

function PaymentItem({ data, isLoading, onClick }) {
  const selected = useApiGet(c.PAYMENT_METHOD, {});
  const isSelected = React.useMemo(
    () => selected.id && selected.id === get(data, 'data.id'),
    [selected, data]
  );
  const handleClick = (e) => {
    e.preventDefault();
    onClick(data);
  };
  if (data.isLoading || isLoading || isEmpty(data)) {
    return (
      <div className="col-span-1">
        <div className="flex items-center border-2 border-gray-400 rounded px-3 py-1 animate-pulse">
          <div className="inline-block h-12 w-12 rounded-full bg-gray-200" />
          <div className="h-3 flex-grow rounded bg-gray-200 ml-2 w-2/3" />
        </div>
      </div>
    );
  }
  return (
    <a
      className="col-span-1 hover:bg-gray-200 cursor-pointer"
      onClick={handleClick}
      href="/"
    >
      <div
        className={`flex items-center border-2 ${
          isSelected ? 'border-red-400' : 'border-gray-200'
        } hover:border-red-600 rounded px-3 py-1 h-full`}
      >
        <div className="inline-block h-12 w-12 relative overflow-hidden">
          <img
            className="object-fit"
            src={get(data, 'data.logo') || method_thumb}
            alt={get(data, 'data.name')}
          />
        </div>
        <div className="font-semibold text-2xs ml-2 leading-snug text-gray-600 w-2/3 text-left">
          <span>{get(data, 'data.name') || 'Payment Method'}</span>
          {/* {data.type === 'CATEGORY' && (
            <span className="ml-1">({(data.sub || []).length})</span>
          )} */}
        </div>
      </div>
    </a>
  );
}

PaymentItem.defaultProps = {
  onClick: () => {},
  data: {},
  isLoading: false,
};

PaymentItem.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
};

function PaymentMethod() {
  const paymentOptions = useApiGet(c.PAYMENT_OPTIONS, {
    loading: true,
  });
  const data = useApiGet(c.INQUIRE, {});
  const isRedirecting = useApiGet('IS_REDIRECTING', false);
  const [category, setCategory] = React.useState(null);
  const [selectedOptions, setSelectedOptions] = React.useState(
    Array.isArray(paymentOptions) ? paymentOptions : []
  );
  const handleSelect = React.useCallback(
    (row) => {
      // console.log('selected code', row?.data?.code);
      if (
        row.type === 'CATEGORY' &&
        c.CATEGORY_WITH_SEARCH.indexOf(row?.data?.code) < 0
      ) {
        if (row.sub_api && get(row, 'sub.0.isLoading')) {
          actions.listCategoryChannel(data.refno, row, (newSub) => {
            setCategory(row);
            setSelectedOptions(newSub);
          });
        }
        setSelectedOptions(row.sub);
        setCategory(row);
        req.set(c.PAYMENT_METHOD, null);
        return;
      }
      req.set(c.PAYMENT_METHOD, row.data);
    },
    [data.refno]
  );
  const handleBack = React.useCallback(
    (e) => {
      e.preventDefault();
      setSelectedOptions(paymentOptions);
      setCategory(null);
      req.set(c.PAYMENT_METHOD, null);
    },
    [paymentOptions]
  );
  React.useEffect(() => {
    if (isEmpty(data)) return;
    if (data.payment_channel !== 'PGI') return;
    if (paymentOptions.loading) actions.getPaymentOptions(data.refno);
  }, [data, paymentOptions]);
  React.useEffect(() => {
    if (Array.isArray(paymentOptions) && isEmpty(selectedOptions)) {
      setSelectedOptions(paymentOptions);
      setCategory(null);
    }
  }, [paymentOptions, selectedOptions]);
  if (get(paymentOptions, 'loading')) {
    return (
      <div>
        <div className="font-semibold my-3 text-gray-700 text-sm">
          Select Payment Method
        </div>
        <div className="grid grid-cols-2 gap-1">
          <PaymentItem isLoading />
          <PaymentItem isLoading />
          <PaymentItem isLoading />
          <PaymentItem isLoading />
          <PaymentItem isLoading />
          <PaymentItem isLoading />
        </div>
      </div>
    );
  }
  if (Array.isArray(paymentOptions)) {
    return (
      <>
        {category ? (
          <div className="font-semibold my-3 text-gray-700 text-xs truncate flex items-center">
            <a
              className="btn secondary rounded xs mr-3 inline-block"
              href="/"
              onClick={handleBack}
            >
              <div className="flex items-center">
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 16l-4-4m0 0l4-4m-4 4h18"
                  />
                </svg>
                <span>Back</span>
              </div>
            </a>
            <span>{get(category, 'data.name')} - Select Payment Method</span>
          </div>
        ) : (
          <div className="font-semibold my-3 text-gray-700 text-xs">
            Select Payment Method
          </div>
        )}
        <div
          className={`grid grid-cols-2 gap-1 fade-in-right ${
            isRedirecting ? 'pointer-events-none' : ''
          }`}
          key={get(category, 'type', 'root')}
        >
          {selectedOptions.map((item) => (
            <PaymentItem
              key={get(item, 'data.code')}
              data={item}
              onClick={handleSelect}
            />
          ))}
        </div>
      </>
    );
  }

  return (
    <>
      <div className="font-semibold my-3 text-gray-700 text-sm">
        Select Payment Method
      </div>
      <div>
        {get(paymentOptions, 'message') ||
          'Unable to load payment options. Please try again later'}
      </div>
    </>
  );
}

PaymentMethod.propTypes = {};

export default PaymentMethod;
