import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { req } from 'react-reqq';
import InputMask from 'react-input-mask';
import img_cc_generic from 'assets/img/cc/generic.png';
// import img_cc_amex from 'assets/img/cc/amex.png';
import img_cc_jcb from 'assets/img/cc/jcb.png';
import img_cc_mastercard from 'assets/img/cc/mastercard.png';
import img_cc_visa from 'assets/img/cc/visa.png';
import img_cc_details_name from 'assets/img/cc/details/name.svg';
import img_cc_details_expiry from 'assets/img/cc/details/expiry.svg';
import img_cc_details_cvv from 'assets/img/cc/details/cvv.svg';

import img_cc_card_expiry from 'assets/img/cc/details/card_expiry.png';
import img_cc_card_cvv from 'assets/img/cc/details/card_cvv.png';

import { INQUIRE } from '../constants';
import CardCompliant from '../components/CardCompliant';

const loadAPI = (id, src) =>
  new Promise((cb) => {
    const fjs = document.getElementsByTagName('script')[0];
    const js = document.createElement('script');
    if (document.getElementById(id)) {
      cb();
      return;
    }
    js.id = id;
    js.src = src;
    js.onload = cb;
    fjs.parentNode.insertBefore(js, fjs);
  });

const KNOWN_CARD = {
  electron: {
    exp: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    logo: img_cc_generic,
  },
  maestro: {
    exp: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    logo: img_cc_generic,
  },
  dankort: {
    exp: /^(5019)\d+$/,
    logo: img_cc_generic,
  },
  interpayment: {
    exp: /^(636)\d+$/,
    logo: img_cc_generic,
  },
  unionpay: {
    exp: /^(62|88)\d+$/,
    logo: img_cc_generic,
  },
  visa: {
    exp: /^4[0-9]{12}(?:[0-9]{3})?$/,
    logo: img_cc_visa,
    mask: '9999 9999 9999 9999',
  },
  mastercard: {
    exp: /^5[1-5][0-9]{14}$/,
    logo: img_cc_mastercard,
    mask: '9999 9999 9999 9999',
  },
  // amex: {
  //   exp: /^3[47][0-9]{13}$/,
  //   logo: img_cc_amex,
  //   mask: '9999 999999 99999',
  // },
  diners: {
    exp: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    logo: img_cc_generic,
  },
  discover: {
    exp: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    logo: img_cc_generic,
  },
  jcb: {
    exp: /^(?:2131|1800|35\d{3})\d{11}$/,
    logo: img_cc_jcb,
    mask: '9999 9999 9999 9999',
  },
};

let DEFAULT_CARD = {
  name: '',
  logo: img_cc_generic,
  mask: '9999 9999 9999 9999',
};

const FIELD_LABELS = {
  CardNo: 'Card Number',
  CardExp: 'Expiry',
  CardHolder: `Card Holder's Name`,
  CardCVV2: 'CVV',
};

const identifyCardType = (card_number) => {
  let card = DEFAULT_CARD;
  _.forOwn(KNOWN_CARD, (v, k) => {
    if (v.exp.test(card_number)) {
      card = {
        name: k.toUpperCase(),
        logo: v.logo,
        mask: v.mask,
      };
    }
  });
  return card;
};

function CardLoader() {
  return (
    <div className="absolute h-full w-full bg-white top-0 z-30">
      <div className="w-2/6 h-8 bg-gray-200 animate-pulse rounded" />
      <div className="w-full h-8 bg-gray-200 animate-pulse rounded mt-8" />
      <div className="w-full h-8 bg-gray-200 animate-pulse rounded mt-10" />
      <div className="mt-10 flex gap-2">
        <div className="w-3/6 h-8 bg-gray-200 animate-pulse rounded" />
        <div className="w-2/6 h-8 bg-gray-200 animate-pulse rounded" />
      </div>
      <div className="w-full h-8 bg-gray-200 animate-pulse rounded mt-8" />
    </div>
  );
}

const fetchCardData = (payload) =>
  new Promise((resolve) => {
    req.put({
      key: 'GHL_DATA',
      url: '/api/channels/card',
      payload,
      onSuccess: (res) => {
        resolve(_.get(res, 'response.data'));
      },
      onError: (res) => {
        req.set(INQUIRE, {
          error: true,
          message: _.get(res, 'response.message') || 'Service unavailable!',
        });
      },
    });
  });

const wait = (t) => new Promise((r) => setTimeout(r, t));

function CardForm({ refno, channel }) {
  const [isInitializing, setIsInitializing] = React.useState(false);
  const [hasInittialized, setHasInitialized] = React.useState(false);
  const [isServiceUnavailable, setIsServiceUnavailable] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const formErrors = React.useRef({});
  const [form, setForm] = React.useState({
    CardNoMasked: '', // '4444 3333 2222 1111',
    CardNo: '', // '4444333322221111',
    CardHolder: '', // 'John Doe',
    CardExpMasked: '', // '12/2020',
    CardExp: '', // '07 / 24',
    CardCVV2: '', // '331',
  });

  const [cardType, setCardType] = React.useState(DEFAULT_CARD);
  const init = async () => {
    try {
      setIsInitializing(true);
      await wait(250);
      const data = await fetchCardData({
        refno,
        channel,
      });
      if (!_.get(data, 'meta.token')) {
        setIsInitializing(true);
        setIsServiceUnavailable(true);
        return;
      }
      setToken(_.get(data, 'meta.token'));
      await loadAPI('j-query', 'https://code.jquery.com/jquery-3.2.0.min.js');
      await loadAPI('ghl-sop', _.get(data, 'meta.js_url'));
      setIsInitializing(false);

      window.eGHL_SOP.init(
        'card-form',
        _.get(data, 'meta.service_id'),
        _.get(data, 'meta.payment_id'),
        _.get(data, 'meta.customer_ip'),
        _.get(data, 'meta.checkout_url')
      );
      setHasInitialized(true);

      window.eGHL_SOP.submitHandler = (info) => {
        let newErrors = { ...formErrors.current };
        _.forOwn(info, (v, k) => {
          if (v.status === 100) {
            newErrors[k] = `${FIELD_LABELS[k] || k} is required`;
          }
          if (v.status === 200) {
            newErrors[k] = `Invalid ${FIELD_LABELS[k] || k} (${
              v.minLength
            } min.)`;
          }
          if (v.status === 300) {
            newErrors[k] = `Invalid ${FIELD_LABELS[k] || k} (${
              v.maxLength
            } max.)`;
          }
          if (v.status === 400) {
            newErrors[k] = `${FIELD_LABELS[k] || k} is invalid format!`;
          }
        });
        formErrors.current = newErrors;
        setErrors(newErrors);
        window.eGHL_SOP.preventCardSubmit = !_.isEmpty(newErrors);
      };
    } catch (err) {
      setIsInitializing(false);
    }
  };
  const resetError = (key) => {
    const newErrors = { ...(formErrors.current || {}) };
    delete newErrors[key];
    setErrors(newErrors);
  };
  const handleChangeInput = ({ target }) => {
    window.eGHL_SOP.preventCardSubmit = true;
    const { name, value } = target;
    setForm({ ...form, [name]: value });
    resetError(name);
  };
  React.useEffect(() => {
    const newCardNo = form.CardNoMasked.replace(/ /g, '').replace(/_/g, '');
    setCardType(identifyCardType(newCardNo));
    setForm({ ...form, CardNo: newCardNo });
    resetError('CardNo');
  }, [form.CardNoMasked]); // eslint-disable-line
  React.useEffect(() => {
    const newCardExp = form.CardExpMasked.replace(/\//g, '').replace(/_/g, '');
    setForm({ ...form, CardExp: newCardExp });
    resetError('CardExp');
  }, [form.CardExpMasked]); // eslint-disable-line
  const validateExpiry = () => {
    const length = `${form.CardExp}`.length;
    if (length < 6) return;
    const m = +form.CardExp.substring(0, 2) - 1;
    const y = +form.CardExp.substr(length - 4);
    let errors = [];
    const cm = new Date().getMonth();
    const cy = new Date().getFullYear();
    if (m > 11) {
      errors = errors.concat('Month is invalid');
    }
    if ((y <= cy && m < cm) || y < cy) {
      errors = errors.concat('Card is expired');
    }
    if (_.isEmpty(errors)) return;
    setErrors({ ...formErrors.current, CardExp: errors.join(', ') });
  };
  React.useEffect(() => {
    validateExpiry();
  }, [form.CardExp]); // eslint-disable-line
  React.useEffect(() => {
    if (!refno || hasInittialized || isInitializing) return;
    init();
  }, []); // eslint-disable-line
  React.useEffect(() => {
    formErrors.current = errors;
  }, [errors]);

  if (isServiceUnavailable) {
    return (
      <div className="flex py-5">
        <div className="text-center m-auto">
          <div className="font-semibold text-red-400">
            Sorry for the inconvenience
          </div>
          <div className="text-sm text-gray-700 font-thin">
            Service is currently under maintenance.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="px-1 relative">
      <form id="card-form">
        <div className="flex items-center justify-between">
          <p
            className="font-semibold mb-3 text-gray-800 text-sm"
            // onDoubleClick={() => {
            //   setForm({
            //     CardNoMasked: '4444 3333 2222 1111',
            //     CardNo: '4444333322221111',
            //     CardHolder: 'John Doe',
            //     CardExpMasked: '12/2020',
            //     CardExp: '07 / 24',
            //     CardCVV2: '331',
            //   });
            // }}
          >
            Your Card Details
          </p>
          {(isInitializing || !hasInittialized) && <CardLoader />}
        </div>
        <div className="fade-in-right">
          <div className="form-group">
            <label className="flex items-center">
              <span className="text-xs text-gray-600 font-thin">
                Card Number
              </span>
              <span className="ml-auto mr-0 text-sm italic font-base text-gray-700">
                {cardType.name || <span>&nbsp;</span>}
              </span>
            </label>
            <div className="form-group-wrapper">
              <span className="form-group-prepend p-0">
                <img
                  src={cardType.logo}
                  alt={cardType.name}
                  className="img-cc"
                />
              </span>
              <InputMask
                disabled={isInitializing || !hasInittialized}
                className="form-control"
                name="CardNoMasked"
                onChange={handleChangeInput}
                mask={cardType.mask || '9999 9999 9999 9999'}
                value={form.CardNoMasked || ''}
                autoComplete="off"
              />
              <input
                type="hidden"
                className="form-control"
                name="CardNo"
                readOnly
                value={form.CardNo}
                data-sop-hash={token}
              />
              {errors['CardNo'] && (
                <span className="error-wrapper-text text-error truncate">
                  {errors['CardNo']}
                </span>
              )}
            </div>
          </div>
          <div className="form-group">
            <label className="text-xs text-gray-600 font-thin">
              Card Holder's Name
            </label>
            <div className="form-group-wrapper">
              <span className="form-group-prepend p-0">
                <img src={img_cc_details_name} alt="Name" className="img-cc" />
              </span>
              <input
                disabled={isInitializing || !hasInittialized}
                className="form-control"
                name="CardHolder"
                onChange={handleChangeInput}
                value={form.CardHolder}
                autoComplete="off"
              />
              {errors['CardHolder'] && (
                <span className="error-wrapper-text text-error truncate">
                  {errors['CardHolder']}
                </span>
              )}
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-3/6">
              <label className="text-xs text-gray-600 font-thin flex items-center">
                <span>Expiry</span>
                <span className="ml-2 font-light italic">MM / YYYY</span>
                <div className="payment-wrapper ml-2">
                  <a
                    className=""
                    href="/"
                    tabIndex="-1"
                    onClick={(e) => e.preventDefault()}
                  >
                    <svg className="icon-xs" viewBox="0 0 24 24">
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
                    </svg>
                    <div className="help-wrapper">
                      <span className="help-inner">
                        <span className="popover-inner above right-align p-2">
                          <p className="mb-2 text-white">
                            Your credit card's expiration date is conveniently
                            printed on the front of your credit card. You'll see
                            a two-digit code for the month and the last two
                            digits or four digits of the year. With many credit
                            card issuers, the card expires on the final day of
                            the month the card is set to expire.
                          </p>
                          <img
                            className="img-fluid"
                            src={img_cc_card_expiry}
                            alt="Card's Expiration"
                          />
                        </span>
                      </span>
                    </div>
                  </a>
                </div>
              </label>
              <div className="form-group-wrapper">
                <span className="form-group-prepend p-0">
                  <img
                    src={img_cc_details_expiry}
                    alt="YYYY/MM"
                    className="img-cc"
                  />
                </span>
                <InputMask
                  disabled={isInitializing || !hasInittialized}
                  className="form-control"
                  name="CardExpMasked"
                  onChange={handleChangeInput}
                  placeholder="MM/YYYY"
                  mask="99/9999"
                  value={form.CardExpMasked || ''}
                  autoComplete="off"
                  onBlur={validateExpiry}
                />
                <input
                  type="hidden"
                  className="form-control"
                  name="CardExp"
                  readOnly
                  value={form.CardExp}
                  autoComplete="off"
                />
                {errors['CardExp'] && (
                  <span className="error-wrapper-text text-error truncate">
                    {errors['CardExp']}
                  </span>
                )}
              </div>
            </div>
            <div className="w-2/6">
              <div className="">
                <label className="text-xs text-gray-600 font-thin flex items-center flex">
                  <span>CVV</span>
                  <div className="payment-wrapper ml-2">
                    <a
                      className=""
                      href="/"
                      tabIndex="-1"
                      onClick={(e) => e.preventDefault()}
                    >
                      <svg className="icon-xs" viewBox="0 0 24 24">
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
                      </svg>
                      <div className="help-wrapper">
                        <span className="help-inner">
                          <span className="popover-inner above right-align p-2">
                            <p className="mb-2 text-white">
                              The CVV Number ("Card Verification Value") on your
                              credit card or debit card is a 3 digit number on
                              VISA&reg;, MasterCard&reg; and Discover&reg;
                              branded credit and debit cards.
                              <br /> On your American Express&reg; branded
                              credit or debit card it is a 4 digit numeric code.
                            </p>
                            <img
                              className="img-fluid"
                              src={img_cc_card_cvv}
                              alt="Card's CVV"
                            />
                          </span>
                        </span>
                      </div>
                    </a>
                  </div>
                </label>
              </div>
              <div className="form-group-wrapper">
                <span className="form-group-prepend p-0">
                  <img src={img_cc_details_cvv} alt="CVV" className="img-cc" />
                </span>
                <input
                  disabled={isInitializing || !hasInittialized}
                  className="form-control"
                  name="CardCVV2"
                  type="password"
                  onChange={handleChangeInput}
                  value={form.CardCVV2}
                  autoComplete="off"
                  maxLength="4"
                />
                {errors['CardCVV2'] && (
                  <span className="error-wrapper-text text-error truncate">
                    {errors['CardCVV2']}
                  </span>
                )}
              </div>
            </div>
          </div>
          {/*
            <div className="bg-gray-100 mb-2 w-full my-1 p-4">
              <label className="text-xs text-gray-600 font-thin flex items-center">
                <span>Additional MDR Charge</span>
                <div className="payment-wrapper ml-2">
                  <a className="" href="/" tabIndex="-1" onClick={(e) => e.preventDefault()}>
                    <svg className="icon-xs" viewBox="0 0 24 24">
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
                    </svg>
                    <div className="help-wrapper">
                      <span className="help-inner">
                        <span className="popover-inner above right-align p-2">
                          <p className="mb-2 text-white">
                            Merchant Discount Rate(MDR) is the cost paid by a merchant to a bank for accepting payment from their customers via credit or debit cards every time a card is used for payments in their stores. The merchant discount rate is expressed in percentage of the transaction amount.
                            </p>
                        </span>
                      </span>
                    </div>
                  </a>
                </div>
              </label>
              <p className="text-xs text-gray-800 font-semibold text-sm">Total Amount &#8369; {amount} + MDR <span className="underline">&#8369;{mdr}</span></p>
            </div>
          */}
        </div>
        <div className="mt-3">
          <input
            name="CC"
            disabled={isInitializing || !hasInittialized}
            type="submit"
            className="btn primary w-full lg rounded-lg"
            value="Checkout"
            // value={`CHECKOUT (₱${totalAmount})`}
          />
        </div>
      </form>
      <CardCompliant />
    </div>
  );
}

CardForm.propTypes = {
  refno: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
};

export default React.memo(CardForm);
