import React from 'react';
// import PropTypes from 'prop-types';

import img_compliant_visa from 'assets/img/compliant/visa.png';
import img_compliant_pcidss from 'assets/img/compliant/pcidss.png';
import img_compliant_mastercard from 'assets/img/compliant/mastercard.png';

const card_compliance_url = `${process.env.PUBLIC_URL}/files/PCIDSS-v3.2.1-Service-Provider-CoC-2020-GHL-ePayments-Sdn-Bhd.pdf`;

function CardCompliant() {
  const [zoom, setZoom] = React.useState(false);
  const handleZoom = (e) => {
    e.preventDefault();
    setZoom(true);
  };
  const handleClose = () => {
    setZoom(false);
  };
  return (
    <div className="flex justify-center compliance-wrapper mt-2">
      <img src={img_compliant_visa} alt="" />
      <a
        href={card_compliance_url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleZoom}
      >
        <img src={img_compliant_pcidss} alt="" />
      </a>
      <img src={img_compliant_mastercard} alt="" />
      {zoom && (
        <div className="lightbox">
          <div className="lightbox-close">
            <button type="button" onClick={handleClose}>
              <span>&times;</span>
            </button>
          </div>
          <div className="iframe-wrapper">
            <iframe
              frameBorder="0"
              src={`${card_compliance_url}#toolbar=0`}
              title="preview"
              height="100%"
            />
          </div>
        </div>
      )}
    </div>
  );
}

CardCompliant.propTypes = {};

export default CardCompliant;
