import React from 'react';
// import PropTypes from 'prop-types';
import { showModal } from 'modules/common/components/Modal';

const Content = () => (
  <>
    <div>
      <p className="mb-2">
        By using Multipay to pay for your online payments, you hereby agree to
        be unconditionally bound by the Terms and Conditions of its use as
        stated in this document.
      </p>
      <div>
        <ul className="list-decimal pl-10">
          <li>
            <p className="mb-2">
              Multipay shall process all instructions received using the
              information you provide.  You shall accept full responsibility for
              all transactions processed through the use of such information
              whether or not processed with your knowledge or by your authority.
            </p>
          </li>
          <li>
            <p className="mb-2">
              You acknowledge that Multipay is only a third-party payment
              processor.    Any terms or conditions for the product or service
              that you purchase or availed using our payment facility is
              strictly between you and the merchants.
            </p>
          </li>
          <li>
            <p className="mb-2">
              We shall not be held liable for failure of the merchant to deliver
              the said product or service as advertised.
            </p>
          </li>
          <li>
            <p className="mb-2">
              You hereby release and hold harmless Multipay from all liability
              arising from the product or service purchase you are about to
              make, as all liability shall reside with the merchant.
            </p>
          </li>
          <li>
            <p className="mb-2">
              We shall not be responsible for any charges imposed on you or any
              other action taken against you by a merchant arising from
              non-processing of your payment due to your failure to follow our
              exact payment instructions.
            </p>
          </li>
          <li>
            We shall be indemnified and not be held liable against any and all
            proceedings, claims, losses, damages or expenses, including legal
            costs, that arise from your use of our system.
          </li>
          <li>
            We shall not be held liable for delays in processing of your payment
            due to corresponding delays, system problems, internal maintenance,
            or maintenance with our payment collection partners. Multipay will
            not be compelled to explain the reasons for such incidents.
          </li>
          <li>
            Your access to Multipay through third-party network providers is
            subject to their respective terms and conditions.  The security of
            data transmitted through such networks is covered by the governing
            policies of the network provider.
          </li>
        </ul>
        <span>
          <p className="mb-2">
            The Terms and Conditions contained herein are governed by the laws
            of the Republic of the Philippines and all suits to enforce this
            Agreement will have to be settled in the proper courts of the City
            of Makati.
          </p>
        </span>
      </div>

      <hr />
      <br />

      <h4 className="text-xl">
        <strong>Data Privacy Consent</strong>
      </h4>
      <p className="mb-2">
        Multipay Corporation (“Multipay”) recognizes its responsibilities under
        Republic Act No. 10173, also known as the Data Privacy Act of 2012 and
        is committed to safeguarding your Personal Information.
      </p>
      <div>
        <h5 className="font-semibold text-lg">
          <i>Coverage</i>
        </h5>
        <p className="mb-2">
          Your Personal Information is needed to register to our payment
          platform which at the minimum includes your full name and contact
          number. Your Personal Information is further required by the billing
          company or government agency in processing your payments through the
          Multipay system. This includes your full name, account number, date of
          birth, address, contact number and Government ID details (as
          applicable).
        </p>
        <h5 className="font-semibold text-lg">
          <i>Data Gathering and Usage</i>
        </h5>
        <p className="mb-2">
          We collect your personal information mainly to verify your identity
          and ensure that your payment details are accurate. Your payment data
          is encoded through our secured payment system and is subsequently
          uploaded to your biller company for the proper posting of your
          payment/s.
        </p>
        <p className="mb-2">
          Furthermore, your information may and shall only be used for the
          following purposes:
        </p>
        <ul className="list-disc pl-10">
          <li>
            <p className="mb-2">Payment history inquiry;</p>
          </li>
          <li>
            <p className="mb-2">
              Multipay promotions, rewards and customer loyalty programs;
            </p>
          </li>
          <li>
            <p className="mb-2">Multipay advisories and updates;</p>
          </li>
          <li>
            <p className="mb-2">
              eKYC processes which may be required by your biller or other
              payment processors;
            </p>
          </li>
          <li>
            <p className="mb-2">
              For data analytics which may be shared to third parties without
              disclosing your personal information.
            </p>
          </li>
          <li>
            <p className="mb-2">
              Credit and Lifestyle scoring which may be shared to third parties.
            </p>
          </li>
        </ul>
        <p className="mb-2">
          Other than the purposes specified, Multipay will not further disclose
          your personal information to third parties without your consent unless
          such disclosure or data sharing is required by applicable laws
          necessary to the said purposes.
        </p>
        <h5 className="font-semibold text-lg">
          <i>Data Retention</i>
        </h5>
        <p className="mb-2">
          Your registered Personal Information and your payment information
          which includes full name and contact number will be stored in our
          database unless you request for the deletion of the same. Multipay has
          established appropriate organizational, physical and technical
          security measures to ensure the protection of your personal
          information.
        </p>
        <h5 className="font-semibold text-lg">
          <i>Customer’s Consent and Declaration</i>
        </h5>
        <p className="mb-2">
          The Personal and Payment Information that I will provide will be made
          in good faith, verified correct to the best of my knowledge, pursuant
          to any Laws and Regulations applicable. I fully understand that
          MULTIPAY CORPORATION is authorized to accept and process payments and
          documents submitted through its payment system.
        </p>
        <p className="mb-2">
          Furthermore, I have read Multipay’s Data Privacy Statement and
          voluntarily and freely provide my consent for Multipay to collect,
          encode, transmit, process, analyze, retrieve, update or modify my
          personal data for registration to Multipay’s online portal and
          processing of my bills and other online payment. I also affirm my
          rights to (a) be informed, (b) access, (c) object, (d) ensure erasure
          of blocking, (e) damages, (f) file a complaint, (g) rectify, and (h)
          data portability.
        </p>
      </div>
    </div>
  </>
);

function Terms() {
  const handleOpenTerms = (e) => {
    e.preventDefault();
    showModal({
      title: 'Terms of Service and Data Privacy',
      content: (onClose) => (
        <div>
          <div className="p-4">
            <div
              className="relative overflow-auto"
              style={{ paddingBottom: '67.3%' }}
            >
              <div className="absolute top-0 h-full w-full text-light text-gray-800 text-sm text-justify px-2">
                <Content />
              </div>
            </div>
          </div>
          <div className="text-right mt-3 p-4 border-t">
            <button className="btn light" type="button" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      ),
    });
  };
  return (
    <div className="text-2xs text-gray-900 font-medium w-56 mx-auto text-center">
      <a href="/" onClick={handleOpenTerms}>
        By clicking PROCEED, you agree with{' '}
        <span className="text-red-600">Terms of Service</span> and{' '}
        <span className="text-red-600">Data Privacy</span>
      </a>
    </div>
  );
}

Terms.propTypes = {};

export default React.memo(Terms);
