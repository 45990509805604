import React from 'react';
// import PropTypes from 'prop-types';
import { req, useApiGet } from 'react-reqq';
import {
  get,
  // isEmpty,
} from 'lodash';
// import { usePersistState } from 'modules/common/helper';
import { useParams } from 'react-router-dom';
import PaymentMethod from './PaymentMethod';
import PaymentMethodWithSearch from './PaymentMethodWithSearch';
import PaymentSubmit from './PaymentSubmit';
import CardForm from '../forms/CardForm';
import MayaCardForm from '../forms/MayaCardForm';
import LandbankPayForm from '../forms/LandbankPayForm';
// import CategoryModal from '../modals/CategoryModal';
import * as c from '../constants';

function PaymentMethodContainer() {
  const { refno } = useParams();
  const data = useApiGet(c.INQUIRE, {});

  const selected = useApiGet(c.PAYMENT_METHOD, {});
  // START TEMP======
  // const temp = useApiGet(c.PAYMENT_METHOD, {});
  // const [selected, setSelected] = usePersistState('selected-method', {});
  // React.useEffect(() => {
  //   if (isEmpty(temp)) return;
  //   setSelected(temp);
  // }, [temp, setSelected])
  // END TEMP========

  const handleBack = React.useCallback((e) => {
    e.preventDefault();
    req.set(c.PAYMENT_METHOD, null);
  }, []);
  if (
    c.CARD_PAYMENT_CODES.indexOf(selected.code) > -1 &&
    !data.is_link_expired &&
    !data.is_transaction_expired
  ) {
    return (
      <div>
        {c.CARD_PAYMENT_CODES.indexOf(data.payment_channel) < 0 && (
          <div className="font-semibold my-3 text-gray-700 text-xs truncate flex items-center">
            <a
              className="btn secondary rounded xs mr-3 inline-block"
              href="/"
              onClick={handleBack}
            >
              <div className="flex items-center">
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 16l-4-4m0 0l4-4m-4 4h18"
                  />
                </svg>
                <span>Back</span>
              </div>
            </a>
            <span>{get(selected, 'name')}</span>
          </div>
        )}
        <div className="mt-3">
          <CardForm refno={refno} channel={selected.code} />
        </div>
      </div>
    );
  }

  if (
    c.LANDBANKPAY_CODES.indexOf(selected.code) > -1 &&
    !data.is_link_expired &&
    !data.is_transaction_expired
  ) {
    return (
      <div>
        {c.LANDBANKPAY_CODES.indexOf(data.payment_channel) < 0 && (
          <div className="font-semibold my-3 text-gray-700 text-xs truncate flex items-center">
            <a
              className="btn secondary rounded xs mr-3 inline-block"
              href="/"
              onClick={handleBack}
            >
              <div className="flex items-center">
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 16l-4-4m0 0l4-4m-4 4h18"
                  />
                </svg>
                <span>Back</span>
              </div>
            </a>
            <span>{get(selected, 'name')}</span>
          </div>
        )}
        <div className="mt-3">
          <LandbankPayForm refno={refno} channel={selected.code} />
        </div>
      </div>
    );
  }

  if (
    c.MAYA_CARD_CODES.indexOf(selected.code) > -1 &&
    // data.with_kyc_form !== 0 &&
    !data.is_link_expired &&
    !data.is_transaction_expired
  ) {
    return (
      <div>
        {c.MAYA_CARD_CODES.indexOf(data.payment_channel) < 0 && (
          <div className="font-semibold my-3 text-gray-700 text-xs truncate flex items-center">
            <a
              className="btn secondary rounded xs mr-3 inline-block"
              href="/"
              onClick={handleBack}
            >
              <div className="flex items-center">
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 16l-4-4m0 0l4-4m-4 4h18"
                  />
                </svg>
                <span>Back</span>
              </div>
            </a>
            <span>{get(selected, 'name')}</span>
          </div>
        )}
        <div className="mt-3">
          <MayaCardForm refno={refno} channel={selected.code} />
        </div>
      </div>
    );
  }
  if (
    c.CATEGORY_WITH_SEARCH.indexOf(selected.code) > -1 &&
    !data.is_link_expired &&
    !data.is_transaction_expired
  ) {
    return (
      <div className="">
        <PaymentMethodWithSearch />
        <PaymentSubmit />
      </div>
      // <CategoryModal onClose={handleBack} />
    );
  }
  if (
    data.status === 'I' &&
    !data.is_link_expired &&
    !data.is_transaction_expired
  ) {
    return (
      <div className="">
        <PaymentMethod />
        <PaymentSubmit />
      </div>
    );
  }
  return <div />;
}

PaymentMethodContainer.propTypes = {};

export default PaymentMethodContainer;
