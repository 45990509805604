export const INQUIRE = 'PAYMENT/inquire';
export const PAY = 'PAYMENT/pay';
export const PAYMENT_OPTIONS = 'PAYMENT/payment_options';
export const SELECTED_OPTIONS = 'PAYMENT/selected_options';
export const PAYMENT_METHOD = 'PAYMENT/payment_method';
export const CARD_CHECKOUT = 'PAYMENT/card_checkout';
export const IFRAME_URL = 'PAYMENT/iframe_url';

export const BPI_CHECKOUT_ACCOUNTS = 'PAYMENT/bpi_checkout_accounts';
export const BPI_CHECKOUT_INITIATE = 'PAYMENT/bpi_checkout_initiate';
export const BPI_CHECKOUT_PROCESS = 'PAYMENT/bpi_checkout_process';

export const CATEGORY_WITH_SEARCH = ['cash_payments', 'ecpay_outlets'];

export const PAYMENT_CODE_OVERRIDE = {
  // cash_payment: 'BAYD',
};

export const REDIRECT_PAYMENT_CODES = ['UBRT'];

export const BPI_CODES = ['BPIRT'];

export const SEVEN_CONNECT_CODES = ['7CONNECT'];

export const CARD_PAYMENT_CODES = ['MAYBANK_SOP'];

export const DPAY_CODES = [
  'DP_711',
  'DP_APP',
  'DP_AUB',
  'DP_BAYD',
  'DP_BDO',
  'DP_BDRX',
  'DP_BOC',
  'DP_BOG',
  'DP_BPIA',
  'DP_BPXB',
  'DP_CBCB',
  'DP_CBXB',
  'DP_CEBL',
  'DP_ECPY',
  'DP_EWXB',
  'DP_I2I',
  'DP_INPY',
  'DP_LBC',
  'DP_LBPA',
  'DP_LBXB',
  'DP_MAYB',
  'DP_MBTC',
  'DP_MBXB',
  'DP_PNBB',
  'DP_PNXB',
  'DP_PSB',
  'DP_PSNT',
  'DP_RCBC',
  'DP_RCXB',
  'DP_RDS',
  'DP_RLNT',
  'DP_RSB',
  'DP_RSBB',
  'DP_SBCB',
  'DP_SMR',
  'DP_UBPB',
  'DP_UBXB',
  'DP_UCPB',
  'DP_UCXB',
  'DRAGONPAY',
];

export const SHOPEE_QR_CODES = ['SHPQR'];
export const SHOPEE_APP_CODES = ['SHPAPP'];
export const SHOPEE_CODES = ['SHP'];

export const MAYA_QR_CODES = ['PAYMAYA_QR'];

export const COINS_CODES = ['COINSPH'];

export const MAYA_CARD_CODES = ['PAYMAYA'];

export const CARD_URL_CODES = ['PAYMAYA_OLD', 'BANCNET'];

export const LANDBANK_CODES = ['LBP_BIZPORTAL'];
export const LANDBANKPAY_CODES = ['LANDBANKPAY'];

export const DIGITAL_PAYMENT_CODES = ['GCASH_WEBPAY'];

export const EWALLET_PAYMENT_CODES = [
  'ALIPAY',
  'WECHATPAY',
  'GRABPAY',
  'UNIONPAY',
  'AUBPAY',
  'SHOPEEPAY',
];
