import React from 'react';
import PropTypes from 'prop-types';
import { toastSuccess } from 'toast';
import { get, isEmpty } from 'lodash';
import * as c from '../constants';
import { useApiLoading } from 'react-reqq';

const LABEL = {
  I: 'Loading...',
  P: 'You can pay this reference number to the selected payment channel.',
  S: 'This reference number is already paid.',
  F: '',
};

const CopyIcon = () => (
  <svg
    className="w-5 h-5"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
    />
  </svg>
);

function PaymentDetails({ data }) {
  const isLoading = useApiLoading(c.INQUIRE, 'get');
  const isBarCode = React.useMemo(() => {
    const code = get(data, 'payment_channel.code');
    if (c.SEVEN_CONNECT_CODES.indexOf(code) > -1 && data.barcode_url) {
      return true;
    }
    return false;
  }, [data]);

  const code = get(data, 'payment_channel.code');

  const handleCopyRefnoToClipboard = (e) => {
    e.preventDefault();
    if (!data.refno) return;
    navigator.clipboard.writeText(data.refno);
    toastSuccess('Copied to clipboard!');
  };

  return (
    <div className="relative">
      {(isEmpty(data) || isLoading) && (
        <div className="absolute h-full w-full top-0 bg-white mt-4">
          <div className="h-56 w-56 sm:h-32 sm:w-32 bg-gray-300 animate-pulse rounded mx-auto" />
          <div className="h-8 w-5/6 bg-gray-300 animate-pulse rounded mx-auto mt-2" />
          <div className="h-2 w-4/6 bg-gray-300 animate-pulse rounded mx-auto mt-1" />
          <div className="h-2 w-3/6 bg-gray-300 animate-pulse rounded mx-auto mt-1" />
        </div>
      )}
      {c.REDIRECT_PAYMENT_CODES.indexOf(code) < 0 &&
      c.BPI_CODES.indexOf(code) < 0 &&
      c.CARD_PAYMENT_CODES.indexOf(code) < 0 &&
      c.MAYA_CARD_CODES.indexOf(code) < 0 &&
      c.LANDBANK_CODES.indexOf(code) < 0 &&
      c.SHOPEE_APP_CODES.indexOf(code) < 0 &&
      c.DIGITAL_PAYMENT_CODES.indexOf(code) < 0 &&
      c.COINS_CODES.indexOf(code) < 0 &&
      c.MAYA_QR_CODES.indexOf(code) < 0 &&
      c.CARD_URL_CODES.indexOf(code) < 0 ? (
        <>
          {isEmpty(data) || isLoading ? (
            <div className="h-56 sm:h-32 sm:w-32" />
          ) : (
            <div>
              {isBarCode ? (
                <div className="h-24 w-full p-1 border rounded mt-4 mb-2 m-auto object-content">
                  <img
                    className="w-full h-full"
                    src={data.barcode_url}
                    alt={data.refno}
                  />
                </div>
              ) : (
                <div className="h-56 w-56 sm:h-32 sm:w-32 p-1 border rounded mt-4 mb-2 mx-auto">
                  <img className="w-full" src={data.qr_url} alt={data.refno} />
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <div className="h-4" />
      )}
      {!isLoading && data?.refno ? (
        <div className="font-bold text-2xl tracking-widest truncate font-mono">
          <a
            className="relative inline-block"
            href="/"
            onClick={handleCopyRefnoToClipboard}
          >
            <span>{data.refno || '-'}</span>
            <span className="text-primary-500 absolute">
              <CopyIcon />
            </span>
          </a>
        </div>
      ) : null}
      {!(data.status === 'P' && c.DPAY_CODES.indexOf(code) > -1) && (
        <div className="text-2xs text-gray-600 leading-snug w-48 mx-auto h-10">
          {LABEL[data.status] || 'Reference Number'}
        </div>
      )}
    </div>
  );
}

PaymentDetails.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default PaymentDetails;
