import React from 'react';
// import PropTypes from 'prop-types';
import { useApiGet, useApiLoading } from 'react-reqq';
import { useParams } from 'react-router-dom';
import Terms from '../components/Terms';
import * as actions from '../actions';
import * as c from '../constants';

function PaymentSubmit(props) {
  const isLoading = useApiLoading(c.PAY, 'put');
  const isRedirecting = useApiGet('IS_REDIRECTING', false);
  const paymentMethod = useApiGet(c.PAYMENT_METHOD, null);
  const { refno } = useParams();
  const handleSubmit = () => {
    const payload = {
      name: '',
      mobile: '',
      agreement: false,
      channel:
        c.PAYMENT_CODE_OVERRIDE[paymentMethod.code] || paymentMethod.code,
      refno,
    };

    if (c.CARD_URL_CODES.indexOf(paymentMethod.code) > -1) {
      actions.payCard(payload);
      return;
    }

    if (c.LANDBANK_CODES.indexOf(paymentMethod.code) > -1) {
      actions.payLandbank(payload);
      return;
    }

    if (c.LANDBANKPAY_CODES.indexOf(paymentMethod.code) > -1) {
      actions.payLandbankPay(payload);
      return;
    }

    if (c.MAYA_CARD_CODES.indexOf(paymentMethod.code) > -1) {
      // console.log('digital!', payload);
      actions.payMayaCard(payload);
      return;
    }

    if (c.SHOPEE_CODES.indexOf(paymentMethod.code) > -1) {
      // console.log('digital!', payload);
      actions.payShopeePay(payload);
      return;
    }

    if (c.SHOPEE_APP_CODES.indexOf(paymentMethod.code) > -1) {
      // console.log('digital!', payload);
      actions.payShopeePayApp(payload);
      return;
    }

    if (c.DIGITAL_PAYMENT_CODES.indexOf(paymentMethod.code) > -1) {
      // console.log('digital!', payload);
      actions.payDigital(payload);
      return;
    }

    if (c.EWALLET_PAYMENT_CODES.indexOf(paymentMethod.code) > -1) {
      // console.log('ewallet!', payload);
      actions.payEWallet(payload);
      return;
    }

    if (c.SHOPEE_QR_CODES.indexOf(paymentMethod.code) > -1) {
      // console.log('ewallet!', payload);
      actions.payShopeePayQr(payload);
      return;
    }

    if (c.COINS_CODES.indexOf(paymentMethod.code) > -1) {
      actions.payCoins(payload);
      return;
    }

    if (c.MAYA_QR_CODES.indexOf(paymentMethod.code) > -1) {
      actions.payMayaQr(payload);
      return;
    }

    if (c.DPAY_CODES.indexOf(paymentMethod.code) > -1) {
      actions.payDPay(payload);
      return;
    }

    if (c.SEVEN_CONNECT_CODES.indexOf(paymentMethod.code) > -1) {
      // console.log('seven connect!', payload);
      actions.paySevenConnect(payload);
      return;
    }

    if (c.REDIRECT_PAYMENT_CODES.indexOf(paymentMethod.code) > -1) {
      // console.log('redirect connect!', payload);
      actions.payRedirect(payload);
      return;
    }

    if (c.BPI_CODES.indexOf(paymentMethod.code) > -1) {
      // console.log('redirect connect!', payload);
      actions.payBpi(payload);
      return;
    }
    // console.log('cash!', payload);
    actions.payCash(payload);
  };
  const buttonLabel = React.useMemo(() => {
    if (isRedirecting) return 'Redirecting...';
    return isLoading ? 'Processing...' : 'Proceed';
  }, [isLoading, isRedirecting]);
  return (
    <>
      <div>
        <div className="my-3 border-t border-dashed pt-3">
          <Terms />
        </div>
        <div className="submit-btn-wrapper text-center">
          <button
            className="btn primary w-2/3 lg rounded-lg submit-btn"
            type="button"
            disabled={!paymentMethod || isLoading || isRedirecting}
            onClick={handleSubmit}
            {...props}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    </>
  );
}

PaymentSubmit.propTypes = {};

export default React.memo(PaymentSubmit);
