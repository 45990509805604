import { req } from 'react-reqq';
import { get, each, pick, isEqual } from 'lodash';
import { transformIncluded, storage, isMobile } from 'modules/common/helper';
import history from 'setup/history';
import card_thumb from 'assets/img/cc/generic.png';
import * as c from './constants';

// FOR TESTING ONLY
// TODO: FOR TESTING
const OVERRIDE = {
  // payment_channel: {
  //   code: 'BPOB',
  //   logo: 'https://ucarecdn.com/16e9c810-c9b8-4430-b985-65905c82ae65/',
  //   name: "BPI"
  // },
  // status: 'P',
  // payment_channel: 'MAYBANK_SOP',
  // is_link_expired: true,
  // is_transaction_expired: true,
};

const redirect = (url, extra = {}) => {
  try {
    const message = {
      type: 'REDIRECT',
      url,
      ...extra,
    };
    window.parent.postMessage(JSON.stringify(message), '*');
  } catch (err) {
    // eslint-disable-next-line
    console.log('Unable to post message!', err);
  }
  req.set('IS_REDIRECTING', true);
  window.location.href = url;
};

export const fetchRates = (refno, params) => {
  req.get({
    key: `${c.PAYMENT_OPTIONS}/rates`,
    url: `/api/transactions/${refno}/rates`,
    params,
    transform: (res) => {
      const rates = {};
      each(get(res, 'data') || [], (item) => {
        rates[get(item, 'attributes.gateway')] = {
          rate: get(item, 'attributes.rate'),
          type: get(item, 'attributes.type') || 'PERCENTAGE',
        };
      });
      return rates;
    },
  });
};

export const inquire = (refno) => {
  req.get({
    key: c.INQUIRE,
    url: `/api/transactions/${refno}`,
    // transform: (res) => get(res, 'data.attributes') || {
    //   error: true,
    //   message: "Sorry for the inconvenience, site currently under maintenance"
    // },
    onSuccess: (res) => {
      const data = {
        ...(get(res, 'response.data.attributes') || {}),
        ...OVERRIDE,
      };
      const { status } = data;
      const default_channel =
        get(data, 'payment_channel.code') || data.payment_channel;

      if (data?.has_redirect_url) {
        req.set('RETURN_URL', 'REALTIME_REDIRECT');
      }
      if (data?.redirect_url) {
        req.set('RETURN_URL', data?.redirect_url);
      }

      if (
        status === 'I' &&
        c.CARD_PAYMENT_CODES.indexOf(default_channel) > -1
      ) {
        fetchRates(refno, {
          gateway: 'MAYBANK_SOP',
        });
        req.set(c.PAYMENT_METHOD, {
          code: 'MAYBANK_SOP',
          logo: card_thumb,
          name: 'Card',
        });
      }
      if (status === 'I' && c.MAYA_CARD_CODES.indexOf(default_channel) > -1) {
        fetchRates(refno, {
          gateway: c.MAYA_CARD_CODES[0],
        });
        req.set(c.PAYMENT_METHOD, {
          code: c.MAYA_CARD_CODES[0],
          logo: card_thumb,
          name: 'Card',
        });
      }
      if (status === 'P') {
        fetchRates(refno, {
          gateway: get(data, 'payment_channel.code') || '',
        });
        if (c.CARD_PAYMENT_CODES.indexOf(default_channel) > -1) {
          history.replace(`/${refno}/checkout`);
          return;
        }
        if (c.BPI_CODES.indexOf(default_channel) > -1) {
          history.replace(`/bpi/${refno}`);
          return;
        }
        history.replace(`/payment-details/${refno}`);
      }
      if (status === 'S') {
        fetchRates(refno, {
          gateway: get(data, 'payment_channel.code') || '',
        });
        history.replace(`/payment-details/${refno}`);
      }
      if (status === 'F') {
        fetchRates(refno, {
          gateway: get(data, 'payment_channel.code') || '',
        });
        history.replace(`/payment-details/${refno}`);
      }
      if (
        status === 'I' &&
        (data.is_link_expired || data.is_transaction_expired)
      ) {
        req.set(c.INQUIRE, {
          error: true,
          message: 'Reference number has expired!',
        });
      }
    },
    transform: (res) => ({
      ...(get(res, 'data.attributes') || {
        error: true,
        message:
          'Sorry for the inconvenience, site currently under maintenance',
      }),
      ...OVERRIDE,
    }),
    onError: (res) => {
      req.set(c.INQUIRE, {
        error: true,
        message: get(res, 'response.message') || 'Reference number not found!',
      });
    },
  });
};

const fetchChannels = (refno, category_id) =>
  new Promise((resolve) => {
    req.get({
      key: `${c.PAYMENT_OPTIONS}/category`,
      url: `/api/transactions/${refno}/categories/${category_id}/channels`,
      onSuccess: (res) => {
        const channels = (get(res, 'response.data') || [])
          .map((item) =>
            transformIncluded(item, get(res, 'response.included') || [])
          )
          .map((item) => ({
            id: item.id,
            ...get(item, 'included.payment_channel.attributes'),
          }));
        resolve(channels);
      },
      onError: () => {
        resolve([]);
      },
    });
  });

const buildOptions = async (refno, categories) => {
  const options = [];
  for (const row of categories) {
    if (row.code === 'uncategorize') {
      const channels = await fetchChannels(refno, row.id);
      each(channels, (channel) => {
        options.push({
          type: 'CHANNEL',
          data: channel,
        });
      });
    }
    if (row.code !== 'uncategorize') {
      options.push({
        type: 'CATEGORY',
        data: row,
        sub: [
          { data: { code: 'placeholder-1' }, isLoading: true },
          { data: { code: 'placeholder-2' }, isLoading: true },
          { data: { code: 'placeholder-3' }, isLoading: true },
          { data: { code: 'placeholder-4' }, isLoading: true },
          { data: { code: 'placeholder-5' }, isLoading: true },
          { data: { code: 'placeholder-6' }, isLoading: true },
        ],
        sub_api: `/api/transactions/${refno}/categories/${row.id}/channels`,
      });
    }
  }
  return options;
};

export const listCategoryChannel = async (refno, row, onSuccess) => {
  const channels = await fetchChannels(refno, row.data.id);
  req.set(c.PAYMENT_OPTIONS, (state) => {
    const sub = channels.map((channel) => ({
      type: 'CHANNEL',
      data: channel,
    }));
    const list = (state[c.PAYMENT_OPTIONS] || []).map((item) =>
      isEqual(item, row)
        ? {
            ...row,
            sub,
            sub_api: false,
          }
        : item
    );
    onSuccess(sub);
    return list;
  });
};

export const getPaymentOptions = (refno) => {
  if (true) {
    req.get({
      key: `${c.PAYMENT_OPTIONS}/category`,
      url: `/api/transactions/${refno}/categories`,
      onSuccess: (res) => {
        const categories = (get(res, 'response.data') || []).map((item) => ({
          id: item.id,
          ...item.attributes,
        }));
        buildOptions(refno, categories).then((res) => {
          req.set(c.PAYMENT_OPTIONS, res);
        });
      },
    });
    fetchRates(refno);
    return;
  }

  Promise.all([
    new Promise((resolve) => {
      req.get({
        key: `${c.PAYMENT_OPTIONS}/category`,
        url: `/api/transactions/${refno}/categories`,
        onSuccess: (res) => resolve(get(res, 'response.data') || []),
      });
    }),
    new Promise((resolve) => {
      req.get({
        key: `${c.PAYMENT_OPTIONS}/channels`,
        url: `/api/transactions/${refno}/channels`,
        onSuccess: (res) => resolve(get(res, 'response.data') || []),
      });
    }),
  ])
    .then(([category, channel]) => {
      const by_category = {};
      const sortedChannel = channel.sort((a, b) =>
        get(a, 'attributes.payment_channel.name') >
        get(b, 'attributes.payment_channel.name')
          ? 1
          : -1
      );
      each(sortedChannel, (item) => {
        const code = get(item, 'attributes.category.code');
        by_category[code] = [
          ...(by_category[code] || []),
          {
            type: 'CHANNEL',
            data: {
              id: item.id,
              ...(get(item, 'attributes.payment_channel') || {}),
              ...pick(get(item, 'attributes') || {}, ['fee', 'fee_type']),
            },
          },
        ];
      });
      let options = [];
      const sortedCategory = category.sort((a, b) =>
        get(a, 'attributes.sequence') > get(b, 'attributes.sequence') ? 1 : -1
      );
      each(sortedCategory, (item) => {
        const code = get(item, 'attributes.code');
        if (code !== 'uncategorize') {
          options.push({
            type: 'CATEGORY',
            data: get(item, 'attributes') || {},
            sub: by_category[code] || [],
          });
          return;
        }
        options = options.concat(by_category[code] || []);
      });
      req.set(c.PAYMENT_OPTIONS, options);
    })
    .catch(() => {
      req.set(c.PAYMENT_OPTIONS, {
        error: true,
        message:
          'Payment methods are currently unavailable, Please try again later',
      });
    });
};

export const inquireCardCheckout = (refno) => {
  req.get({
    key: c.CARD_CHECKOUT,
    url: `/api/transactions/maybank/${refno}`,
    transform: (res) => {
      const data = transformIncluded(res.data, res.included || []);
      const method = {
        ...pick(get(data, 'attributes.payment_channel') || {}, [
          'code',
          'name',
          'logo',
        ]),
        mdr_fee: get(
          data,
          'included.transaction_card_maybank.attributes.mdr_fee'
        ),
      };
      req.set(c.PAYMENT_METHOD, method);
      return {
        action_url:
          get(
            data,
            'included.transaction_card_maybank.attributes.action_url'
          ) || {},
        payload:
          get(
            data,
            'included.transaction_card_maybank.attributes.request_payload'
          ) || {},
      };
    },
  });
};

export const payCash = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/cash',
    payload,
    onSuccess: (res, state) => {
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: newRefno,
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const paySevenConnect = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/seven_connect',
    payload,
    onSuccess: (res, state) => {
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: newRefno,
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const payDigital = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/digital',
    payload,
    onSuccess: (res, state) => {
      const redirect_url = get(res, 'response.data.url');
      if (redirect_url) {
        redirect(redirect_url, { channel: payload.channel });
        return;
      }
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: newRefno,
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const payShopeePayApp = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/shopeepay_app',
    payload,
    onSuccess: (res, state) => {
      const redirect_url = get(res, 'response.data.url');
      if (redirect_url) {
        redirect(redirect_url, { channel: payload.channel });
        return;
      }
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: newRefno,
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const payShopeePay = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/shopeepay',
    payload: {
      ...payload,
      platform: isMobile() ? 'MOBILE' : 'WEB',
    },
    onSuccess: (res, state) => {
      const redirect_url = get(res, 'response.data.url');
      if (redirect_url) {
        redirect(redirect_url, { channel: payload.channel });
        return;
      }
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: newRefno,
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const payCard = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/card',
    payload,
    onSuccess: (res, state) => {
      const redirect_url = get(res, 'response.data.url');
      if (redirect_url) {
        redirect(redirect_url, { channel: 'CARD' });
        return;
      }
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: newRefno,
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const payMayaCard = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/paymaya',
    payload,
    onSuccess: (res, state) => {
      const redirect_url = get(res, 'response.data.url');
      if (redirect_url) {
        redirect(redirect_url, { channel: 'PAYMAYA' });
        return;
      }
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: newRefno,
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const payMayaQr = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/paymaya_qr',
    payload,
    onSuccess: (res, state) => {
      const redirect_url = get(res, 'response.data.url');
      if (redirect_url) {
        redirect(redirect_url, { channel: 'PAYMAYA' });
        return;
      }
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: newRefno,
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const payCoins = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/coinsph',
    payload,
    onSuccess: (res, state) => {
      const redirect_url = get(res, 'response.data.url');
      if (redirect_url) {
        redirect(redirect_url, { channel: 'COINSPH' });
        return;
      }
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: newRefno,
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const payDPay = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/dpay',
    payload,
    onSuccess: (res, state) => {
      const redirect_url = get(res, 'response.data.url');
      if (redirect_url) {
        redirect(redirect_url, { channel: payload.channel });
        return;
      }
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: newRefno,
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const payLandbank = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/landbank',
    payload,
    onSuccess: (res, state) => {
      const redirect_url = get(res, 'response.data.url');
      if (redirect_url) {
        redirect(redirect_url);
        return;
      }
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: newRefno,
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const payLandbankPay = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/landbankpay',
    payload,
    onSuccess: (res, state) => {
      const redirect_url = get(res, 'response.data.url');
      if (redirect_url) {
        redirect(redirect_url);
        return;
      }
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: get(res, 'response.data.attributes.refno'),
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const payEWallet = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/aub',
    payload,
    onSuccess: (res, state) => {
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: get(res, 'response.data.attributes.refno'),
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const payShopeePayQr = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/shopeepay_qr',
    payload,
    onSuccess: (res, state) => {
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: get(res, 'response.data.attributes.refno'),
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const payRedirect = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/bank/unionbank',
    payload,
    onSuccess: (res, state) => {
      const redirect_url = get(res, 'response.data.url');
      if (redirect_url) {
        redirect(redirect_url);
        return;
      }
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: newRefno,
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const payBpi = (payload) => {
  req.put({
    key: c.PAY,
    url: '/api/channels/bank/bpi/auth',
    payload,
    onSuccess: (res, state) => {
      const redirect_url = get(res, 'response.data.url');
      if (redirect_url) {
        redirect(redirect_url);
        return;
      }
      const newRefno = get(res, 'response.data.attributes.refno');
      req.set(c.INQUIRE, {
        ...state[c.INQUIRE],
        qr_url: get(res, 'response.data.attributes.qr_url'),
        refno: newRefno,
        status: 'P',
      });
      history.replace(`/payment-details/${newRefno}`);
    },
  });
};

export const fetchBpiAccounts = (refno) => {
  const key = `${refno}-acccounts`;
  const cached = storage.get(key, []);
  if (cached.length > 0) {
    req.set(c.BPI_CHECKOUT_ACCOUNTS, cached);
    return;
  }
  req.post({
    key: c.BPI_CHECKOUT_ACCOUNTS,
    url: '/api/channels/bank/bpi/list_accounts',
    payload: {
      refno,
    },
    onSuccess: (res) => {
      const value = (res.response || []).map((item, id) => ({
        id,
        label: item.accountPreferredName || item.accountType,
        account_number: item.accountNumber,
        token: item.accountNumberToken,
      }));
      storage.set(key, value);
      req.set(c.BPI_CHECKOUT_ACCOUNTS, value);
    },
  });
};

export const initBpiCheckout = (refno, { token }, onSuccess, onError) => {
  req.post({
    key: c.BPI_CHECKOUT_INITIATE,
    url: '/api/channels/bank/bpi/initiate',
    payload: {
      refno,
      account_number_token: token,
    },
    onSuccess,
    onError,
  });
};

export const processBpiCheckout = (refno, payload, onError) => {
  req.post({
    key: c.BPI_CHECKOUT_PROCESS,
    url: '/api/channels/bank/bpi/process',
    payload,
    onSuccess: () => {
      req.set(c.INQUIRE, (state) => ({
        ...state[c.INQUIRE],
        status: 'S',
      }));
      history.replace(`/payment-details/${refno}`);
    },
    onError,
  });
};
