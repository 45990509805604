import React from 'react';
// import PropTypes from 'prop-types';
import { useApiGet } from 'react-reqq';
import { useParams, Redirect } from 'react-router-dom';
import { get, each, isEmpty } from 'lodash';
import method_thumb from 'assets/img/logo-method.png';
import TransactionDetails from '../components/TransactionDetails';
import * as actions from '../actions';
import * as c from '../constants';

function CheckoutContainer() {
  const { refno } = useParams();
  const data = useApiGet(c.INQUIRE, {});
  const checkoutData = useApiGet(c.CARD_CHECKOUT, {});
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    const frm = document.createElement('form');
    frm.action = checkoutData.action_url;
    frm.method = 'POST';
    each(checkoutData.payload, (v, k) => {
      const inp = document.createElement('input');
      inp.type = 'hidden';
      inp.name = k;
      inp.value = v;
      frm.appendChild(inp);
    });
    document.body.appendChild(frm);
    frm.submit();
  };

  React.useEffect(() => {
    actions.inquire(refno);
  }, [refno]); // eslint-disable-line

  React.useEffect(() => {
    if (isEmpty(data)) return;
    if (data.status !== 'P') return;
    actions.inquireCardCheckout(data.refno);
  }, [data]);

  if (data.status === 'I') {
    return <Redirect to={`/payment-details/${refno}`} />;
  }
  if (!isEmpty(data) && data.status !== 'P') {
    return <Redirect to={`/payment-details/${refno}`} />;
  }
  return (
    <div className="container mx-auto max-w-400 receipt-mask">
      <div className="bg-white shadow-lg flex flex-wrap divide-x overflow-hidden">
        <div className="px-2 pt-2 sm:px-5 sm:pt-5 w-full">
          <div className="border-dashed border-b pb-0 sm:pb-3 flex flex-col sm:flex-row items-center justify-center sm:justify-start">
            <div className="h-10 w-10 mb-2">
              <img
                className="object-fit"
                src={get(data, 'payment_channel.logo') || method_thumb}
                alt="Payment Method"
              />
            </div>
            <div className="ml-3 text-center sm:text-left">
              <div className="text-xs text-gray-500">
                Selected payment option:
              </div>
              <div className="text-red-600 font-bold text-xs leading-snug">
                {get(data, 'payment_channel.name') || 'Loading...'}
              </div>
            </div>
          </div>
          <div className="bg-gray-100 text-gray-800 font-thin p-4 mt-5">
            <div className="text-xs text-teal-500 font-semibold">
              Verify that the following information is correct.
            </div>
            <div className="text-xs text-gray-900">
              If you are satisfied with the payment information, click the Pay
              Now button.
            </div>
          </div>
          <div>
            <TransactionDetails data={data} />
          </div>
          <div className="mb-4">
            <button
              className="btn primary w-full lg rounded-lg"
              type="button"
              disabled={isEmpty(data) || isEmpty(checkoutData) || isLoading}
              onClick={handleSubmit}
            >
              <span>
                <svg
                  className="mr-2 inline-block"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path
                    fill="#fff"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="1"
                    d="M12.421 0c3.25 0 5.895 2.67 5.895 5.95l-.001 2.892H20c.43 0 .784.321.836.737l.006.105v11.228a.842.842 0 01-.603.808l-7.579 2.245a.84.84 0 01-.478 0L4.603 21.72A.842.842 0 014 20.912V9.684c0-.465.377-.842.842-.842h1.684V5.951C6.526 2.744 9.052.12 12.2.004zm6.737 10.526H5.684v9.758l6.737 1.996 6.737-1.996v-9.758zm-6.737 3.93c.465 0 .842.377.842.842v2.246a.842.842 0 11-1.684 0v-2.246c0-.465.377-.842.842-.842zm0-12.772c-2.322 0-4.21 1.914-4.21 4.267L8.21 8.842h8.421V5.951c0-2.281-1.775-4.15-4-4.262z"
                  ></path>
                </svg>
                {isLoading ? 'Processing...' : 'Pay Now'}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

CheckoutContainer.propTypes = {};

export default CheckoutContainer;
