import React from 'react';
// import PropTypes from 'prop-types';
import icon_user from 'assets/img/cc/details/name.svg';
import FormPhoneNumber from 'modules/common/components/FormPhoneNumber';
import 'react-phone-input-2/lib/plain.css';
import { useParams } from 'react-router';
import { useApiGet, useApiLoading } from 'react-reqq';
import * as actions from '../actions';
import * as c from '../constants';
import Terms from '../components/Terms';
// import PaymentSubmit from '../components/PaymentSubmit';

export const PURGE_IGNORE = () => (
  <input className="react-tel-input" type="tel" />
);

function LandbankPayForm() {
  const [form, setForm] = React.useState({
    mobile_number: '',
  });

  const { refno } = useParams();
  const paymentMethod = useApiGet(c.PAYMENT_METHOD, null);
  const isLoading = useApiLoading(c.PAY, 'put');
  const isRedirecting = useApiGet('IS_REDIRECTING', false);
  const buttonLabel = React.useMemo(() => {
    if (isRedirecting) return 'Redirecting...';
    return isLoading ? 'Processing...' : 'Proceed';
  }, [isLoading, isRedirecting]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const payload = {
      mobile: form.mobile_number.replace('639', '9'),
      refno,
      channel: paymentMethod.code,
    };
    actions.payLandbankPay(payload);
  };
  return (
    <div className="px-1 relative">
      <form onSubmit={handleSubmit}>
        <div className="flex items-center justify-between">
          <p className="font-semibold mb-3 text-gray-800 text-sm">
            Please provide the following information:
          </p>
        </div>
        <div className="fade-in-right">
          <div className="mb-2">
            <FormPhoneNumber
              name="mobile_number"
              onChange={setForm}
              value={form.mobile_number}
              label={
                <span>
                  Enter Mobile Number (
                  <span className="font-bold" style={{ color: '#f00' }}>
                    registered with LandbankPay
                  </span>
                  )
                </span>
              }
              icon={icon_user}
              required
              componentProps={{
                countryCodeEditable: false,
              }}
            />
          </div>
        </div>
        {/* <div className="border-dashed border-t mt-3 py-2">
          <div className="flex align-center justify-center flex-col">
            <div className="text-2xs text-gray-500 tracking-wide mb-1 inline-block text-center">
              Powered by:
            </div>
            <div className="flex justify-center space-x-3">
              land bank logo here??
            </div>
          </div>
        </div> */}
        <div className="mb-3 border-t border-dashed pt-3">
          <Terms />
        </div>
        <div className="submit-btn-wrapper text-center">
          <button
            className="btn primary w-2/3 lg rounded-lg submit-btn"
            type="submit"
            disabled={isLoading || isRedirecting}
          >
            {buttonLabel}
          </button>
        </div>
        {/* <PaymentSubmit type="submit" disabled /> */}
      </form>
    </div>
  );
}

LandbankPayForm.propTypes = {};

export default LandbankPayForm;
