import React from 'react';
import { useParams } from 'react-router-dom';
import qs from 'query-string';

export const PDF_URLS = {
  smart:
    'https://drive.google.com/file/d/1aPnjXSOWsoWgO1mps0gBa4EoAXww0cn6/preview#toolbar=0&navpanes=0&scrollbar=0',
  pldt:
    'https://drive.google.com/file/d/1VD62NvQzPlQyk2CgFZ2aSDOpfNUKLe2p/preview#toolbar=0&navpanes=0&scrollbar=0',
  csb:
    'https://drive.google.com/file/d/15ratXCtJaBQcWSqvz7q-zM1Vhg4wu6oR/preview#toolbar=0&navpanes=0&scrollbar=0',
};

function PdfContainer() {
  const [pdf, setPdf] = React.useState(null);
  const { type } = useParams();

  React.useEffect(() => {
    const params = qs.parse(window.location.search);
    if (`${params.scroll}` === '0') {
      document.body.className = 'overflow-hidden preview';
    }
    if (type) {
      const url = PDF_URLS[type];
      if (url) setPdf(url);
    }
  }, [type]);
  if (!pdf) return null;
  return (
    <div className="w-screen pointer-events-none">
      <iframe
        width="100%"
        height="250px"
        src={`${pdf}?scroll=0#toolbar=0`}
        type="application/pdf"
        style={{ height: 10000 }}
        frameBorder="0"
        scrolling="no"
        seamless="seamless"
        title={type}
      />
    </div>
  );
}

export default PdfContainer;
