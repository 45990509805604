import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import GenericView, { ViewItem } from 'modules/common/components/GenericView';

export const renderListArray = (arr) => {
  if (Array.isArray(arr))
    return (
      <ul className="list-decimal pl-6 space-y-1">
        {React.Children.toArray(arr.map((x) => <li>{x}</li>))}
      </ul>
    );
  return arr;
};

const PaymentDetails = ({ data }) => {
  return (
    <GenericView data={{}}>
      <div className="divide-y divide-dashed">
        {(data || []).map((row) => (
          <ViewItem
            label={row[0]}
            value={() => (
              <div
                className="text-right flex flex-col"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(row[1] || ''),
                }}
              />
            )}
          />
        ))}
      </div>
    </GenericView>
  );
};

PaymentDetails.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

function PaymentInstructions({ data }) {
  if (Object.keys(data?.external_payment_instructions || {}).length > 0) {
    return (
      <div className="bg-gray-50 text-gray-800 h-full w-full sm:absolute mt-4 sm:mt-0">
        <div className="h-full w-full overflow-auto divide-y px-4">
          <div className="py-2 font-bold text-xs uppercase text-gray-500 tracking-wider text-center">
            Payment Details
          </div>

          {/* {custom_instruction.introMsg ? (
            <div className="py-4">{custom_instruction.introMsg}</div>
          ) : null} */}

          {data?.external_payment_instructions?.paymentDetails ? (
            <div className="py-4">
              <PaymentDetails
                data={data?.external_payment_instructions?.paymentDetails}
              />
            </div>
          ) : null}

          {data?.external_payment_url ? (
            <div className="flex justify-center py-4">
              <button
                className="btn outline-primary"
                type="button"
                onClick={() => {
                  window.location.href = data?.external_payment_url;
                }}
              >
                Click here to view detailed instructions
              </button>
            </div>
          ) : null}

          {/* {custom_instruction.depositInstructions ? (
            <div className="py-4">
              <div className="font-bold text-center">Step 1: Pay</div>
              {renderListArray(custom_instruction.depositInstructions)}
            </div>
          ) : null}

          {custom_instruction.validateInstructions ? (
            <div className="py-4">
              <div className="font-bold text-center">Step 2: Validate</div>
              {renderListArray(custom_instruction.validateInstructions)}
            </div>
          ) : null}

          {custom_instruction.confirmInstructions ? (
            <div className="py-4">
              <div className="font-bold text-center">Step 3: Confirmation</div>
              {renderListArray(custom_instruction.confirmInstructions)}
            </div>
          ) : null}

          {custom_instruction.disclaimer ? (
            <div className="py-4">
              <div className="font-bold text-center">Disclaimer</div>
              {custom_instruction.disclaimer}
            </div>
          ) : null} */}
        </div>
      </div>
    );
  }
  return (
    <div className="bg-gray-50 text-gray-800 font-thin h-full w-full sm:absolute">
      <div
        id="payment-instructions"
        className="h-full w-full overflow-auto divide-y px-4 text-xs font-normal"
      >
        {get(data, 'payment_channel.instruction') && (
          <div
            className="py-4"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                get(data, 'payment_channel.instruction') || ''
              ),
            }}
          />
        )}
        {get(data, 'guidelines') && (
          <div
            className="py-4"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(get(data, 'guidelines') || ''),
            }}
          />
        )}
      </div>
    </div>
  );
}

PaymentInstructions.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default PaymentInstructions;
