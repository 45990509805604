import React from 'react';
// import PropTypes from 'prop-types';

function CloseWindow() {
  return (
    <div className="container mx-auto max-w-sm receipt-mask">
      <div className="bg-white shadow-lg flex overflow-hidden relative pb-5  h-64">
        <div className="m-auto">
          <div className="text-center font-bold text-gray-800 leading-4 mb-2">
            Transaction Complete
          </div>
          <div className="flex justify-center mb-2 text-green-500">
            <svg
              className="w-24 h-24"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className="text-center font-light text-xs text-gray-800 leading-4">
            You may now close this window
          </div>
        </div>
      </div>
    </div>
  );
}

CloseWindow.propTypes = {};

export default CloseWindow;
