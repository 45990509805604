import React from 'react';
import { isMobile } from './common/helper';

const UPDATES = [
  // {
  //   date: 'DATE',
  //   items: [
  //     'ITEM 1',
  //   ],
  // },
  {
    date: 'May 31 2023',
    items: ['Implement ?back=true|{url} parameter'],
  },
  {
    date: 'May 17 2023',
    items: ['Fix copy to clipboard behavior'],
  },
  {
    date: 'Aug 22 2022',
    items: ['Implement LandbankPay redirect'],
  },
  {
    date: 'Apr 29 2022',
    items: ['Update channel logo'],
  },
  {
    date: 'Mar 31 2022',
    items: ['Implement more dpay channel codes'],
  },
  {
    date: 'Jan 26, 2022',
    items: ['Removed redundant expiry date'],
  },
  {
    date: 'Jan 24, 2022',
    items: ['Added more dpay channel code'],
  },
  {
    date: 'Jan 20, 2022',
    items: [
      'Added more dpay channel codes',
      'Implement external payment',
      'Handle description tags',
    ],
  },
  {
    date: 'Jan 18, 2022',
    items: ['Added dpay channel codes'],
  },
  {
    date: 'Nov 08, 2021',
    items: ['Copy reference number to clipboard'],
  },
  {
    date: 'Oct 06, 2021',
    items: ['Added dpay channel code'],
  },
  {
    date: 'Sep 29, 2021',
    items: ['Improve mobile UX'],
  },
  {
    date: 'Sep 17, 2021',
    items: ['Show close button if opener exists'],
  },
  {
    date: 'Sep 07, 2021',
    items: ['Hotfix visa mastercard payload issue'],
  },
  // {
  //   date: 'Aug 23, 2021',
  //   items: ['Implement no form option on credit card method'],
  // },
  {
    date: 'Aug 11, 2021',
    items: ['Update channel IDs'],
  },
  {
    date: 'Aug 10, 2021',
    items: ['Implement platform in payload (shopeeapp)'],
  },
  {
    date: 'Jun 25, 2021',
    items: ['Implement shopeeapp', 'Update shopee qr endpoint'],
  },
  {
    date: 'Jun 24, 2021',
    items: ['Implement coinsph'],
  },
  {
    date: 'Jun 23, 2021',
    items: ['Implement assistance instruction'],
  },
  {
    date: 'Jun 22, 2021',
    items: ['Implement shopeepay via aub'],
  },
  {
    date: 'Jun 21, 2021',
    items: ['Update post message payload on dpay channels'],
  },
  {
    date: 'Jun 18, 2021',
    items: ['Implement shopeepay'],
  },
  {
    date: 'Jun 17, 2021',
    items: ['Implement more dpay channels'],
  },
  {
    date: 'Jun 16, 2021',
    items: [
      'Omit payment instruction on some channels',
      'Apply channel code on post message(digital)',
    ],
  },
  {
    date: 'Jun 15, 2021',
    items: [
      'Implement dpay channels',
      'Include channel in redirect post message',
    ],
  },
  {
    date: 'Jun 9, 2021',
    items: ['Refactor all redirects with post message'],
  },
  {
    date: 'Jun 8, 2021',
    items: ['Implement Paymaya Card Updates'],
  },
  {
    date: 'May 20, 2021',
    items: [
      'Implement Paymaya QR',
      'Implement postmessage redirect',
      'Fetch Rates even failed',
      'Implement Redirect URL',
      'Implement postmessage close window',
    ],
  },
  {
    date: 'May 07, 2021',
    items: ['Improve ecpay outlets ux'],
  },
  {
    date: 'Apr 09, 2021',
    items: ['Implement environment warning'],
  },
  {
    date: 'Mar 01, 2021',
    items: ['Update Footer'],
  },
  {
    date: 'Jan 27, 2021',
    items: ['Implement BPI(Realtime)', 'Implement caching'],
  },
  {
    date: 'Jan 20, 2021',
    items: ['Implement Unionbank realtime', 'Update font on reference number'],
  },
  {
    date: 'Jan 16, 2021',
    items: ['Update error message on unavailable service'],
  },
  {
    date: 'Dec 11, 2020',
    items: ['Fix typo'],
  },
  {
    date: 'Nov 26, 2020',
    items: ['Support legacy urls'],
  },
  {
    date: 'Nov 17, 2020',
    items: ['Implement return url'],
  },
  {
    date: 'Nov 13, 2020',
    items: ['Updated Cash Payment UI'],
  },
  {
    date: 'Nov 12, 2020',
    items: ['Remove extra header on mobile view'],
  },
  {
    date: 'Nov 11, 2020',
    items: [
      'Fixed inconsistent back button',
      'Adjust details layout',
      'Improve details on mobile view',
    ],
  },
  {
    date: 'Oct 22, 2020',
    items: ['Added Landbank Biz Portal', 'Added Release Notes'],
  },
];

function Version() {
  return (
    <div className="container mx-auto max-w-700 receipt-mask">
      <div className="bg-white shadow-lg flex flex-wrap divide-x overflow-hidden relative pb-5">
        <div className="p-2 sm:p-5 w-full">
          <div className="text-center font-bold text-gray-800 leading-4">
            MultiSys PGI
          </div>
          <div className="text-center font-light text-xs text-gray-800 leading-4">
            Release Notes - Version 1.0 - {isMobile() ? 'Mobile' : 'Web'}
          </div>
          <div className="border-t mt-3">
            <div className="divide-y">
              {UPDATES.map((row) => (
                <div className="py-2" key={row.date}>
                  <div className="text-xs font-semibold">
                    {row.date || 'Update'}
                  </div>
                  <ul className="text-xs list-disc pl-4 text-gray-600">
                    {(row.items || []).map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Version.propTypes = {};

export default Version;
