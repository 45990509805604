import React from 'react';
import PropTypes from 'prop-types';

function FormInput({ label, name, onChange, value, icon, ...props }) {
  const handleChange = ({ target }) => {
    onChange((state) => ({
      ...state,
      [name]: target.value,
    }));
  };
  return (
    <>
      <label className="text-xs text-gray-600 font-thin flex items-center">
        <span>{label}</span>
      </label>
      <div className="form-group-wrapper">
        {icon && (
          <span className="form-group-prepend p-0">
            <img src={icon} alt="Name" className="img-cc" />
          </span>
        )}
        <input
          className="form-control"
          name={name}
          onChange={handleChange}
          value={value}
          autoComplete="off"
          {...props}
        />
      </div>
    </>
  );
}

FormInput.defaultProps = {
  label: '',
};

FormInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.bool,
    PropTypes.string,
  ]),
};

export default React.memo(FormInput);
