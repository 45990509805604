import React from 'react';
// import PropTypes from 'prop-types';
import cn from 'classnames';
import { useApiGet } from 'react-reqq';
import { useParams, Redirect } from 'react-router-dom';
import { get } from 'lodash';
import method_thumb from 'assets/img/logo-method.png';
import PaymentDetails from '../components/PaymentDetails';
import TransactionError from '../components/TransactionError';
import TransactionDetails from '../components/TransactionDetails';
import EndAction from '../components/EndAction';
import HelpInstructions from '../components/HelpInstructions';
import * as actions from '../actions';
import * as c from '../constants';
import PaymentInstructions from '../components/PaymentInstructions';

function DetailsContainer() {
  const { refno } = useParams();
  const data = useApiGet(c.INQUIRE, {});
  React.useEffect(() => {
    window.scrollTo(0, 0);
    actions.inquire(refno);
  }, [refno]); // eslint-disable-line
  if (
    (data.status === 'I' &&
      !data.is_link_expired &&
      !data.is_transaction_expired) ||
    data.error
  ) {
    return <Redirect to={`/${refno}`} />;
  }

  const withExternal =
    Object.keys(data?.external_payment_instructions || {}).length > 0;

  return (
    <div className="container shadow-lg mx-auto max-w-700 receipt-mask pb-6 bg-white">
      <div className="bg-white flex flex-wrap divide-x overflow-hidden">
        <div className="px-1 pt-2 sm:px-5 sm:pt-5 w-full sm:w-2/5">
          <div className="border-none sm:border-dashed border-b pb-0 sm:pb-3 flex flex-col sm:flex-row items-center justify-center sm:justify-start">
            <div className="h-10 w-10 mb-2">
              <img
                className="object-fit"
                src={get(data, 'payment_channel.logo') || method_thumb}
                alt="Payment Method"
              />
            </div>
            <div className="ml-3 text-center sm:text-left">
              <div className="text-xs text-gray-500">
                Selected payment option:
              </div>
              <div className="text-red-600 font-bold text-xs leading-snug">
                {get(data, 'payment_channel.name') || 'Loading...'}
              </div>
            </div>
          </div>
        </div>
        <div className="px-2 pt-2 sm:px-5 sm:pt-5 w-full sm:w-3/5 flex flex-col">
          {!withExternal && data?.expires_at ? (
            <div className="text-center sm:text-right">
              {data.status === 'P' ? (
                <>
                  <div className="text-xs text-gray-500 leading-none">
                    Valid Until:
                  </div>
                  <div className="text-gray-800 font-semibold text-sm">
                    {get(data, 'expires_at') || '-'}
                  </div>
                </>
              ) : (
                <>
                  <div className="text-xs text-gray-500 leading-none">
                    Transaction Date
                  </div>
                  <div className="text-gray-800 font-semibold text-sm">
                    {get(data, 'transaction_date') || '-'}
                  </div>
                </>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div className="bg-white flex flex-wrap divide-x">
        <div className="px-3 w-full sm:w-2/5">
          {!withExternal ? (
            <div className="text-center">
              <PaymentDetails data={data} />
            </div>
          ) : (
            <div className="mb-4" />
          )}
          {data.error ? (
            <TransactionError data={data} />
          ) : (
            <TransactionDetails data={data} />
          )}
          <EndAction refno={refno} />
          <HelpInstructions data={data} />
        </div>
        <div
          className={cn(
            'px-3 sm:p-5 w-full sm:w-3/5 flex flex-col',
            withExternal ? 'md:-mt-20' : ''
          )}
        >
          <div className="flex-grow sm:relative min-h-495">
            <PaymentInstructions data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}

DetailsContainer.propTypes = {};

export default DetailsContainer;
