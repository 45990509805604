import React from 'react';
import PropTypes from 'prop-types';
import img from 'assets/img/third-party/bpi.png';
import { isEmpty } from 'lodash';
import { useApiGet, useApiLoading } from 'react-reqq';
import * as actions from '../actions';
import * as c from '../constants';

const ResendOtp = ({ onClick }) => {
  const [countDown, setCountDown] = React.useState(0);
  const [isSending, setIsSending] = React.useState(false);
  const handleResend = (e) => {
    e.preventDefault();
    setIsSending(true);
    onClick(
      () => {
        setIsSending(false);
        setCountDown(120);
        const tick = () => {
          setCountDown((state) => {
            const newState = state - 1;
            if (newState > 0) setTimeout(tick, 1000);
            return newState;
          });
        };
        setTimeout(tick, 1000);
      },
      () => {
        setIsSending(false);
        setCountDown(0);
      }
    );
  };
  if (countDown > 0) {
    return (
      <span className="font-medium text-red-300 w-20 inline-block text-left">
        Sent({countDown})
      </span>
    );
  }
  if (isSending) {
    return (
      <span className="font-medium text-red-300 w-20 inline-block text-left">
        Sending...
      </span>
    );
  }
  return (
    <a
      href="/"
      className="font-medium text-red-500 hover:underline w-20 inline-block text-left"
      onClick={handleResend}
    >
      Resend OTP
    </a>
  );
};

ResendOtp.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const INIT_FORM = {
  otp: '',
};

function BpiCheckout({ refno }) {
  const isListLoading = useApiLoading(c.BPI_CHECKOUT_ACCOUNTS, 'post');
  const accounts = useApiGet(c.BPI_CHECKOUT_ACCOUNTS, []);
  const [temp, setTemp] = React.useState({});
  const [selected, setSelected] = React.useState(null);
  const [form, setForm] = React.useState(INIT_FORM);
  const isLoading = useApiLoading(c.BPI_CHECKOUT_INITIATE, 'post');
  const handleHighlight = (newSelected) => (e) => {
    e.preventDefault();
    setTemp(newSelected);
  };

  const handleSelectAccount = (newSelected) => (e) => {
    e.preventDefault();
    actions.initBpiCheckout(refno, newSelected, ({ response }) => {
      setForm(INIT_FORM);
      setSelected({
        account: newSelected,
        response,
      });
    });
  };
  const handleResendOTP = (onSuccess, onError) => {
    actions.initBpiCheckout(
      refno,
      selected.account,
      ({ response }) => {
        setForm(INIT_FORM);
        setSelected({
          account: selected.account,
          response,
        });
        onSuccess();
      },
      onError
    );
  };
  const handleBack = (e) => {
    e.preventDefault();
    setSelected(null);
  };
  const [isProcessing, setIsProcessing] = React.useState(false);
  const handleSubmit = () => {
    setIsProcessing(true);
    const payload = {
      refno,
      transaction_id: selected?.response?.transaction_id,
      otp: form.otp,
    };
    actions.processBpiCheckout(refno, payload, () => {
      setIsProcessing(false);
    });
  };
  React.useEffect(() => {
    actions.fetchBpiAccounts(refno);
  }, [refno]);
  const isSent = false;
  return (
    <div className="mb-5">
      <div className="py-8">
        <img
          className="h-16 mx-auto object-center object-contain"
          src={img}
          alt="BPI Logo"
        />
      </div>
      {!isEmpty(selected || {}) ? (
        <div className="fade-in-right" key="otp">
          <div className="border rounded-lg p-3 mb-3">
            <div className="font-semibold my-3 text-gray-700 text-xs truncate flex items-center">
              {false && (
                <a
                  className="btn secondary rounded xs mr-3 inline-block"
                  href="/"
                  onClick={handleBack}
                >
                  <div className="flex items-center">
                    <svg
                      className="w-5 h-5 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M7 16l-4-4m0 0l4-4m-4 4h18"
                      />
                    </svg>
                    <span>Back</span>
                  </div>
                </a>
              )}
              <span>{selected?.account?.label}</span>
            </div>
            <div className="text-gray-800 font-light text-lg">
              Enter your One-Time Password
            </div>
            <div className="text-gray-700 font-thin mb-3">
              {selected?.response?.message ||
                'To continue, please enter the OTP sent to your mobile device'}
              {/* To continue, please enter the OTP sent to your mobile device
              ending in <b>+63916****448.</b> */}
            </div>
            <div className="mb-2">
              <input
                className="form-control"
                disabled={isLoading || isProcessing}
                onChange={({ target }) =>
                  setForm((s) => ({ ...s, otp: target.value }))
                }
                value={form.otp}
                autoComplete="chrome-off|off"
              />
            </div>
            <div className="text-xs text-gray-600">
              {isLoading
                ? 'Sending OTP...'
                : `OTP Valid Until ${
                    selected?.response?.expiration_datetime_full || 'n/a'
                  }`}
            </div>
            <div className="text-xs text-gray-600 text-center mt-3">
              {!isSent ? (
                <span>
                  Did not receive OTP? <ResendOtp onClick={handleResendOTP} />
                </span>
              ) : (
                <span>
                  Did not receive OTP?{' '}
                  <a
                    href="/"
                    className="font-medium text-green-500 hover:underline"
                  >
                    Sent
                  </a>
                </span>
              )}
            </div>
          </div>
          <div>
            <button
              className="btn bpi rounded-full w-full"
              type="button"
              onClick={handleSubmit}
              disabled={isLoading || isProcessing}
            >
              {isProcessing ? 'Please wait...' : 'Submit'}
            </button>
          </div>
        </div>
      ) : (
        <div className="fade-in-right" key="select-account">
          <div className="text-gray-600 font-light mb-3">
            Please choose your account
          </div>
          <div className="h-48 overflow-auto mb-3 space-y-2">
            {accounts.length < 1 || isListLoading ? (
              <>
                <div className="border rounded-lg p-2 mb-2">
                  <div className="w-4/6 h-6 bg-gray-200 animate-pulse rounded mb-2" />
                  <div className="w-3/6 h-4 bg-gray-200 animate-pulse rounded" />
                </div>
                {/* <div className="border rounded-lg p-2 mb-2">
                  <div className="w-4/6 h-6 bg-gray-200 animate-pulse rounded mb-2" />
                  <div className="w-3/6 h-4 bg-gray-200 animate-pulse rounded" />
                </div> */}
              </>
            ) : (
              accounts.map((item) => (
                <a
                  className={`${
                    temp === item
                      ? 'border-2 border-bpi-500'
                      : 'border-2 hover:bg-gray-100'
                  } rounded-lg p-3 block`}
                  href="/"
                  onClick={handleHighlight(item)}
                  key={item.id}
                >
                  <div className="text-gray-600 text-base font-medium tracking-tight leading-tight">
                    {item.label}
                  </div>
                  <div className="text-gray-800 leading-tight font-semibold text-xl">
                    {item.account_number}
                  </div>
                </a>
              ))
            )}
          </div>
          <div>
            <button
              className="btn bpi rounded-full w-full"
              type="button"
              disabled={isEmpty(temp) || isLoading}
              onClick={handleSelectAccount(temp)}
            >
              {isEmpty(temp) ? 'Select Account' : 'Continue'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

BpiCheckout.propTypes = {
  refno: PropTypes.string.isRequired,
};

export default BpiCheckout;
