import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const ViewContext = React.createContext();

export const ViewItem = ({ label, value, loadingClassName }) => {
  const { data, isLoading } = React.useContext(ViewContext);
  const renderValue = React.useMemo(() => {
    if (typeof value === 'function') return value(data);
    return get(data, value) || 'n/a';
  }, [data, value]);
  if (isLoading) {
    return (
      <div className="mb-2 flex" style={{ height: 39 }}>
        <div className={loadingClassName}>&nbsp;</div>
      </div>
    );
  }
  return (
    <div className="py-2 flex gap-2 items-start justify-between">
      <div className="text-xs text-gray-600 font-normal">{label}</div>
      <div className="text-xs text-gray-800 font-semibold">{renderValue}</div>
    </div>
  );
};
ViewItem.defaultProps = {
  loadingClassName: 'bg-gray-200 w-full rounded animate-pulse my-auto',
};

ViewItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.func.isRequired])
    .isRequired,
  loadingClassName: PropTypes.string,
};

function GenericView({ data, children, isLoading }) {
  return (
    <>
      <ViewContext.Provider value={{ data, isLoading }}>
        {children}
      </ViewContext.Provider>
    </>
  );
}

GenericView.propTypes = {
  isLoading: false,
};

GenericView.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool,
  children: PropTypes.instanceOf(Object).isRequired,
};

export default GenericView;
