import React from 'react';
// import PropTypes from 'prop-types';
import not_found from 'assets/img/page-not-found.png';

function MaintenancePage() {
  return (
    <div className="flex">
      <div className="text-center mx-auto mt-10">
        <div className="text-gray-700 text-3xl sm:text-5xl font-light">
          Server Maintenance
        </div>
        <div className="text-gray-700 px-10">
          Sorry for the inconvenience, We are currently running a server
          maintenance
        </div>
        <div className="text-gray-700 mb-8 px-10">Please try again later.</div>
        <div className="mx-auto max-w-700 w-full min-h-200 mb-5">
          <img src={not_found} alt="not found" />
        </div>
      </div>
    </div>
  );
}

MaintenancePage.propTypes = {};

export default MaintenancePage;
