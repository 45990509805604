import React from 'react';
// import PropTypes from 'prop-types';
import poweredPaymaya from 'assets/img/powered-by/paymaya.png';
import poweredKount from 'assets/img/powered-by/kount.png';
import icon_user from 'assets/img/cc/details/name.svg';
import icon_email from 'assets/img/cc/details/email.svg';
import icon_location from 'assets/img/cc/details/location.svg';
import icon_country from 'assets/img/cc/details/country.svg';
import icon_province from 'assets/img/cc/details/province.svg';
import icon_city from 'assets/img/cc/details/city.svg';
import icon_zip from 'assets/img/cc/details/zip.svg';
import countries from 'modules/common/countries.json';
import provinces from 'modules/common/provinces.json';
import cities from 'modules/common/cities.json';
import FormInput from 'modules/common/components/FormInput';
import FormSelect from 'modules/common/components/FormSelect';
import FormPhoneNumber from 'modules/common/components/FormPhoneNumber';
import 'react-phone-input-2/lib/plain.css';
import { useParams } from 'react-router';
import { useApiGet, useApiLoading } from 'react-reqq';
import * as actions from '../actions';
import * as c from '../constants';
import Terms from '../components/Terms';
// import PaymentSubmit from '../components/PaymentSubmit';

const MAP_STATE_LABEL = {
  PH: 'Province',
  default: 'State',
};

const MAP_CITY_LABEL = {
  PH: 'City/Municipality',
  default: 'City',
};

export const PURGE_IGNORE = () => (
  <input className="react-tel-input" type="tel" />
);

function MayaCardForm() {
  const [form, setForm] = React.useState({
    first_name: '',
    // middle_name: '',
    last_name: '',
    contact_phone: '',
    contact_email: '',
    billing_address_line1: '',
    // billing_address_line2: '',
    billing_address_city: '',
    billing_address_state: '',
    billing_address_zip_code: '',
    billing_address_country_code: 'PH',
  });
  const handleChangeCountry = (v) => {
    const { billing_address_country_code } = v();
    setForm((state) => ({
      ...state,
      billing_address_country_code,
      billing_address_city: '',
      billing_address_state: '',
    }));
  };
  const countryOptions = React.useMemo(
    () =>
      countries
        .map((x) => ({
          value: x.iso2,
          label: x.name,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)),
    []
  );
  const provinceOptions = React.useMemo(
    () =>
      provinces
        .map((x) => ({
          value: x.id,
          label: x.name,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)),
    []
  );
  const cityOptions = React.useMemo(
    () =>
      (cities[form.billing_address_state] || [])
        .map((x) => ({
          value: x.id,
          label: x.name,
          zip_code: x.zip_code,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)),
    [form.billing_address_state]
  );

  const { refno } = useParams();
  const paymentMethod = useApiGet(c.PAYMENT_METHOD, null);
  const isLoading = useApiLoading(c.PAY, 'put');
  const isRedirecting = useApiGet('IS_REDIRECTING', false);
  const buttonLabel = React.useMemo(() => {
    if (isRedirecting) return 'Redirecting...';
    return isLoading ? 'Processing...' : 'Proceed';
  }, [isLoading, isRedirecting]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { billing_address_city, billing_address_state, ...rest } = form;

    const payload = {
      ...rest,
      refno,
      channel: paymentMethod.code,
      billing_address_city,
      billing_address_state,
    };

    if (form.billing_address_country_code === 'PH') {
      payload.billing_address_city =
        cityOptions.find((x) => `${x.value}` === `${billing_address_city}`)
          ?.label || '';
      payload.billing_address_state =
        provinceOptions.find((x) => `${x.value}` === `${billing_address_state}`)
          ?.label || '';
    }

    actions.payMayaCard(payload);
  };

  React.useEffect(() => {
    if (form.billing_address_country_code !== 'PH') return;
    setForm((state) => ({
      ...state,
      billing_address_zip_code:
        cityOptions.find((x) => `${x.value}` === `${form.billing_address_city}`)
          ?.zip_code || '',
    }));
  }, [
    form.billing_address_country_code,
    cityOptions,
    form.billing_address_city,
  ]);
  return (
    <div className="px-1 relative">
      <form onSubmit={handleSubmit}>
        <div className="flex items-center justify-between">
          <p className="font-semibold mb-3 text-gray-800 text-sm">
            Please provide the following information:
          </p>
        </div>
        <div className="fade-in-right">
          <div className="grid grid-cols-2 gap-2">
            <div className="mb-2">
              <FormInput
                style={{ paddingLeft: '0.75rem' }}
                name="first_name"
                onChange={setForm}
                value={form.first_name}
                label="First Name"
                required
              />
            </div>
            <div className="mb-2">
              <FormInput
                style={{ paddingLeft: '0.75rem' }}
                name="last_name"
                onChange={setForm}
                value={form.last_name}
                label="Last Name"
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="mb-2">
              <FormPhoneNumber
                name="contact_phone"
                onChange={setForm}
                value={form.contact_phone}
                label="Mobile Number"
                icon={icon_user}
                required
              />
            </div>
            <div className="mb-2">
              <FormInput
                name="contact_email"
                onChange={setForm}
                value={form.contact_email}
                icon={icon_email}
                label="Email Address"
                type="email"
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="mb-2">
              <FormSelect
                name="billing_address_country_code"
                onChange={handleChangeCountry}
                value={form.billing_address_country_code}
                icon={icon_country}
                label="Country"
                options={countryOptions}
                required
              />
            </div>
            <div className="mb-2">
              {form.billing_address_country_code === 'PH' ? (
                <FormSelect
                  name="billing_address_state"
                  onChange={setForm}
                  value={form.billing_address_state}
                  icon={icon_province}
                  label={
                    MAP_STATE_LABEL[form.billing_address_country_code] ||
                    MAP_STATE_LABEL.default
                  }
                  options={provinceOptions}
                  required
                />
              ) : (
                <FormInput
                  name="billing_address_state"
                  onChange={setForm}
                  value={form.billing_address_state}
                  icon={icon_province}
                  label={
                    MAP_STATE_LABEL[form.billing_address_country_code] ||
                    MAP_STATE_LABEL.default
                  }
                  required
                />
              )}
            </div>
          </div>
          <div className="grid grid-cols-3 gap-2">
            <div className="mb-2 col-span-2">
              {form.billing_address_country_code === 'PH' ? (
                <FormSelect
                  name="billing_address_city"
                  onChange={setForm}
                  value={form.billing_address_city}
                  icon={icon_city}
                  label={
                    MAP_CITY_LABEL[form.billing_address_country_code] ||
                    MAP_CITY_LABEL.default
                  }
                  options={cityOptions}
                  optionsPlaceholder={
                    !form.billing_address_state
                      ? '- Select Province -'
                      : '- Select City -'
                  }
                  required
                />
              ) : (
                <FormInput
                  name="billing_address_city"
                  onChange={setForm}
                  value={form.billing_address_city}
                  icon={icon_user}
                  label={
                    MAP_CITY_LABEL[form.billing_address_country_code] ||
                    MAP_CITY_LABEL.default
                  }
                  required
                />
              )}
            </div>
            <div className="mb-2">
              <FormInput
                name="billing_address_zip_code"
                onChange={setForm}
                value={form.billing_address_zip_code}
                icon={icon_zip}
                label="Zip Code"
              />
            </div>
          </div>
          <div className="mb-2">
            <FormInput
              name="billing_address_line1"
              onChange={setForm}
              value={form.billing_address_line1}
              icon={icon_location}
              label="Address"
              required
            />
          </div>
        </div>
        <div className="border-dashed border-t mt-3 py-2">
          <div className="flex align-center justify-center flex-col">
            <div className="text-2xs text-gray-500 tracking-wide mb-1 inline-block text-center">
              Powered by:
            </div>
            <div className="flex justify-center space-x-3">
              <img
                className="object-contain object-center h-4"
                src={poweredPaymaya}
                alt="Paymaya"
              />
              <img
                className="object-contain object-center h-4"
                src={poweredKount}
                alt="Paymaya"
              />
            </div>
          </div>
        </div>
        <div className="mb-3 border-t border-dashed pt-3">
          <Terms />
        </div>
        <div className="submit-btn-wrapper text-center">
          <button
            className="btn primary w-2/3 lg rounded-lg submit-btn"
            type="submit"
            disabled={isLoading || isRedirecting}
          >
            {buttonLabel}
          </button>
        </div>
        {/* <PaymentSubmit type="submit" disabled /> */}
      </form>
    </div>
  );
}

MayaCardForm.propTypes = {};

export default MayaCardForm;
