import React from 'react';
import PropTypes from 'prop-types';

function FormSelect({
  label,
  name,
  onChange,
  value,
  icon,
  options,
  optionsPlaceholder,
  ...props
}) {
  const handleChange = ({ target }) => {
    onChange((state) => ({
      ...state,
      [name]: target.value,
    }));
  };
  return (
    <>
      <label className="text-xs text-gray-600 font-thin flex items-center">
        <span>{label}</span>
      </label>
      <div className="form-group-wrapper">
        {icon && (
          <span className="form-group-prepend p-0">
            <img src={icon} alt="Name" className="img-cc" />
          </span>
        )}
        <select
          className="form-control"
          name={name}
          onChange={handleChange}
          value={value}
          autoComplete="off"
          {...props}
        >
          <option value="">{optionsPlaceholder}</option>
          {options.map((x) => (
            <option key={x.value} value={x.value}>
              {x.label}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

FormSelect.defaultProps = {
  label: '',
  options: [],
  optionsPlaceholder: '- Select -',
};

FormSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.bool,
    PropTypes.string,
  ]),
  options: PropTypes.instanceOf(Array).isRequired,
  optionsPlaceholder: PropTypes.string,
};

export default React.memo(FormSelect);
